import React, { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import { Typography } from '@mui/material'
import Link from 'next/link'
import colors from '@const/colors.json'
import { withProps } from '@utils/hoc'
import { withStyles } from '@mui/styles'

export interface CTAProps {
  wrapper?: FC<PropsWithChildren<any>>
  url: string
  label: string | ReactNode
  icon?: ReactNode
  reverse?: boolean
  color?: string
  onClick?: MouseEventHandler
  classes?: string
  hover?: string
  before?: ReactNode
  after?: ReactNode
  target?: string
  ariaLabel?: string
  rel?: string
}

const CTA: FC<CTAProps> = ({ wrapper, label, icon, url, reverse, color, classes = '', hover = colors.blue3, before, after, target = '_self', onClick, rel, ariaLabel = null }) => {
  const CustomTypography = withStyles({
    root: {
      color: color,
      '&:hover': {
        color: hover
      },
      '&:active': {
        color: hover
      }
    }
  })(withProps(Typography, { component: 'span' }))

  const noop = ({ children }) => <div>{children}</div>

  const Wrapper = wrapper ?? noop

  return (
    <Link href={url} passHref={true} target={target} onClick={onClick} aria-label={ariaLabel} rel={rel}>
      <Wrapper>
        <div className={`${reverse ? 'flex-row-reverse justify-end' : 'flex-row justify-start'} flex flex-nowrap items-center gap-[10px]`}>
          <CustomTypography className={`flex gap-x-1 normal-case ${classes}`}>
            {before}
            {label}
            {after}
          </CustomTypography>
          {icon && <div className="flex-shrink-0">{icon}</div>}
        </div>
      </Wrapper>
    </Link>
  )
}

export default CTA
