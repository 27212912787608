import { withProps } from '@utils/hoc'
import { withStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const TitleField = withStyles({
  root: {
    fontSize: '35px'
  }
})(withProps(Typography, { variant: 'h1', component: 'h1' }))

export default TitleField
