import React from 'react'

/**
 * Simple Higher Order Component to inject an initialised subset of props in a component.
 * @TODO: TypeScript support
 *
 * @param Component
 * @param props
 *
 */
const withProps = (Component, props) => {
  return own => <Component {...{ ...props, ...own }}>{own.children}</Component>
}

export default withProps
