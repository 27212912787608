import React from 'react'
import { Article } from '@common/models/article/article'
import Image from 'next/image'
import { Query } from '@core/global/interfaces'
import { SkBadge } from '@core/components/badge/skBadge'
import { SkIconUilTagAlt, SkImageFake } from '@core/global/icons'
import classNames from 'classnames'
import { coreStore } from '@core/store/coreStore'
import { ApiStore } from '@core/api/apiStore'
import { VariantTracked } from '../variantTracked/variantTracked'
import Link from 'next/link'
import { Tag } from '@common/models/tag/tag'
import { Price, SkFormatPrice } from '@common/models/price/price'
import { Http } from '@core/api/http'
import { action, observable, runInAction } from 'mobx'
import { Message } from '@core/service/message'
import { Photo } from '@common/models/photo/photo'
import { Variant } from '@common/models/variant/variant'

export class ArticleStore extends ApiStore<Article> {
  @observable
  $setPriceByTagLoading: boolean

  constructor() {
    super(coreStore.paramAppStore.apiUrl, '/article', Article, 'male', 'Articles', 'un article', 'cet article', SkIconUilTagAlt, '/articles', 'route')
  }

  async $save(item: Article, query?: Query<Article>, withMessageSave?: boolean, withEventStoreModified = true): Promise<Article> {
    // set index in order
    if (item.articleTags) {
      item.articleTags.forEach((x, index) => {
        item.articleTags[index].idx = index + 1
      })
    }
    item.articleAttributes = item.articleAttributes?.filter(x => x.attribute)
    return super.$save(item, query, withMessageSave, withEventStoreModified)
  }

  $cancelArticleDeletion = async (item: Article) => {
    try {
      await Http.$put(`${this.baseUrl}/${item.id}/cancel-deleted`).then(() => {
        Message.success('L‘article a été réactivé ainsi que ses variants.')
      })
    } finally {
      await this.$reload(item)
    }
  }

  @action
  async $setPriceByTag(data: { tag: Tag; price: Price }) {
    try {
      this.$setPriceByTagLoading = true
      await Http.$put(`${this.baseUrl}/price-by-tag`, data)
      Message.success('Les prix ont bien été modifiés')
    } finally {
      runInAction(() => (this.$setPriceByTagLoading = false))
    }
  }

  @action
  async $deleteArticleAttribute(articleId: number, articleAttributeId: number) {
    return Http.$delete<Article>(`${this.baseUrl}/${articleId}/article-attribute/${articleAttributeId}`).then(data => {
      Message.success('Propriété supprimée')
      return data
    })
  }
}

export function SkDefaultImage(props: {
  item: Partial<{ id: number; photo: Photo } & ({ photos: Photo[]; photo?: never } | { photos?: never; photo: Photo })>
  size: 'sm' | 'md' | 'lg'
  bg?: 'white'
  shadow?: boolean
}) {
  const { item, size, bg, shadow } = props
  const photo = item?.photo ? item.photo : item?.photos?.length > 0 ? item.photos[0] : null
  const width = size === 'sm' ? 25 : size === 'md' ? 50 : 100
  const height = size === 'sm' ? 25 : size === 'md' ? 50 : 100

  return (
    <div
      style={{ width, height }}
      className={classNames('flex-center image-contents relative overflow-hidden rounded bg-gray-100', { 'bg-white': bg === 'white' }, { 'shadow-sm': shadow })}
    >
      {photo ? (
        <Image alt="article" style={{ objectFit: 'contain' }} src={photo.url} width={width} height={height} />
      ) : (
        <div className="flex-center">
          <SkImageFake size={size} />
        </div>
      )}
    </div>
  )
}

export function SkArticleImageListItem(props: {
  article?: Article
  variantTracked?: VariantTracked
  variant?: Variant
  size?: 'sm' | 'md' | 'lg'
  canEdit?: boolean
  showPrice?: boolean
  noLink?: boolean
}) {
  const { article, variantTracked, variant, size = 'md', showPrice, noLink } = props

  function getContent() {
    return (
      <div className="flex items-center justify-start">
        <SkDefaultImage item={getItemWithImage()} size={size} />
        <div className={classNames({ 'ml-2': size === 'sm' }, { 'ml-4': size === 'md' }, { 'ml-6': size === 'lg' })}>
          <div>
            <span className="font-light">{variantTracked ? variantTracked.variant.fullLabelT : variant ? variant.fullLabelT : article.fullLabel}</span>
          </div>
          {variantTracked?.barcode && <div className="font-semibold">{variantTracked.barcode}</div>}
          {variantTracked?.isFromSubscription && <SkBadge success>Abonnement associé</SkBadge>}
          {showPrice && (
            <div className="pt-1 font-semibold">{<SkFormatPrice price={variantTracked ? variantTracked.variant.transactionPrice?.price : variant.transactionPrice?.price} />}</div>
          )}
        </div>
      </div>
    )
  }

  function getItemWithImage(): Partial<{ id: number; photos: Photo[] }> {
    const itemsThatCanHavePhotos = [variantTracked?.variant, variant, variantTracked?.variant?.article, variant?.article, article]
    for (const i of itemsThatCanHavePhotos) {
      if (i?.photos?.length) {
        return i
      }
    }
    return article
  }

  return (
    <>
      {variantTracked?.id && !noLink ? (
        <Link href={`/${variantTracked.variant.article.isSingleTracked ? 'variants-tracked-by-single' : 'variants-tracked-by-lot'}/${variantTracked.id}`}>{getContent()}</Link>
      ) : (
        getContent()
      )}
    </>
  )
}
