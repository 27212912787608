import React, { FC } from 'react'
import { CTA, Link } from '@components/ui'
import PhoneIcon from '@assets/images/svg/ico-tel-blue.svg'
import FacebookIcon from '@assets/images/svg/ico-fbk-blue.svg'
import routes from '@const/routes'

const MenuDesktop: FC = () => {
  return (
    <ul className="top-[110px] flex full:space-x-[30px] responsive:hidden">
      <li className="flex justify-center hover:text-super-ya-blue3">
        <CTA url={routes.menus.uri} label="Nos Menus" />
      </li>
      <li className="flex justify-center">
        <CTA url={routes.comment_ca_marche.uri} label="Comment ça marche ?" />
      </li>
      <li className="flex justify-center">
        <CTA url={routes.contact.uri} label="Nous contacter" />
      </li>
      <li className="flex justify-center">
        <CTA
          url="tel:+687288260"
          label={
            <span className="flex items-center justify-center">
              <PhoneIcon />
              <span className="pl-2 text-center">28 82 60</span>
            </span>
          }
        />
      </li>
      <li className="flex justify-center">
        <Link href="https://www.facebook.com/superyanoumea" external={true} ariaLabel="Voir notre page Facebook" target="_blank" rel="noopener">
          <div className="flex items-center justify-center">
            <FacebookIcon />
          </div>
        </Link>
      </li>
    </ul>
  )
}

export default MenuDesktop
