import { useCallback, useEffect, useRef, useState } from 'react'
import { EstimateLine } from '@common/models/estimateLine/estimateLine'
import { useCard } from '@utils/context/cardContext'

interface IData {
  days: Array<number>
  items: Array<Array<EstimateLine>>
}

const useDays = () => {
  const isMounted = useRef<boolean>(false)
  const { card } = useCard()

  const [data, setData] = useState<IData>(() => ({ days: [], items: [] }))

  const getDays = useCallback(estimateLines => {
    // get raw days
    const rawDays: Array<number> = estimateLines.map(element => Date.parse(element.planning.date.toString()))

    // get unique days
    const uniqueDays: Array<number> = [...new Set(rawDays)]

    // sort and set days
    uniqueDays.sort((a, b) => a - b)

    return uniqueDays
  }, [])

  const getItemsByDay = useCallback(
    (uniqueDays, estimateLines) =>
      uniqueDays.map(day => {
        return estimateLines.filter(line => Date.parse(line.planning.date.toString()) === day)
      }),
    []
  )

  useEffect(() => {
    isMounted.current = true
    return () => void (isMounted.current = false)
  }, [])

  useEffect(() => {
    if (!isMounted.current) {
      return
    }
    const { estimateLines } = card

    const uniqueDays = getDays(estimateLines)

    // get items by day
    const itemsByDay = getItemsByDay(uniqueDays, estimateLines)

    // set days and items
    setData({ days: uniqueDays, items: itemsByDay })
  }, [card])

  return data
}

export default useDays
