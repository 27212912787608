import React from 'react'
import { Typography } from '@mui/material'
import { FlatButton, Link } from '@components/ui'
import AccountIcon from '@assets/images/svg/ico-compte.svg'
import routes from '@const/routes'
import { useStore } from '@utils/hooks'
import { useRouter } from 'next/router'

const Account = () => {
  const { store } = useStore()
  const router = useRouter()

  const user = store?.meStore?.data?.customer
  const link = user ? routes.mon_compte.uri : routes.connexion.uri
  const hidden = router.asPath === routes.connexion.uri

  return !hidden ? (
    <Link href={link}>
      <FlatButton variant="outlined" color="primary" className="h-12.5 min-w-0 bg-white px-[13px]">
        <div className="flex">
          <AccountIcon />
          <div className="pl-2 mobile:hidden">
            <Typography color="textPrimary" noWrap={true} className="capitalize">
              {(user && `${user?.firstName} ${user?.lastName.substr(0, 1)}.`) || 'Mon compte'}
            </Typography>
          </div>
        </div>
      </FlatButton>
    </Link>
  ) : null
}

export default Account
