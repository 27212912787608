import { AbstractEstimate } from '@common/models/estimate/abstractEstimate'
import { computed, makeObservable, observable } from 'mobx'
import { CodeBundleEnum, EstimateStateEnum, EstimateTypeEnum, PaymentStateEnum } from '@common/global/enums'
import { referentialStore } from '@common/models/referential/referentialStore'
import { Price } from '@common/models/price/price'
import { Document } from '@core/models/document'
import { AbstractLabelObservable } from '@core/models/abstractLabelObservable'
import { InvoicePlan } from '@common/models/invoicePlan/invoicePlan'

export class Estimate extends AbstractEstimate {
  subscription: AbstractEstimate
  @observable
  paymentState: AbstractLabelObservable
  remainingAmountToPay: Price
  remainingAmountToInvoice: Price
  totalPayed: Price
  idx: number
  file: Document
  validityDate: Date
  @observable
  isCommissionable: boolean
  subscriptionsAssociated: { id: number; invoicePlan: InvoicePlan }[]

  constructor(data?: Partial<Estimate>) {
    super(data)
    makeObservable(this)
    if (!data?.id) {
      this.type = new AbstractLabelObservable({ id: EstimateTypeEnum.estimate })
      this.state = referentialStore.data.estimateStates.find(x => x.id === EstimateStateEnum.draft)
    }
    this.subscription = data?.subscription
    this.paymentState = data?.paymentState
    this.endDate = data?.endDate
    this.remainingAmountToPay = data?.remainingAmountToPay
    this.remainingAmountToInvoice = data?.remainingAmountToInvoice
    this.totalPayed = data?.totalPayed
    this.idx = data?.idx
    this.file = data?.file
    this.validityDate = data?.validityDate
    this.isCommissionable = data?.isCommissionable
    this.subscriptionsAssociated = data?.subscriptionsAssociated
  }

  @computed
  get isOpen() {
    return this.state?.id === EstimateStateEnum.open
  }

  @computed
  get isDraft() {
    return this.state?.id === EstimateStateEnum.draft
  }

  @computed
  get isValidated() {
    return this.state?.id === EstimateStateEnum.validated
  }

  @computed
  get isCanceled() {
    return this.state?.id === EstimateStateEnum.canceled
  }

  @computed
  get isRejected() {
    return this.state?.id === EstimateStateEnum.rejected
  }

  @computed
  get isDisabled() {
    return !!this.paymentState
  }

  @computed
  get canDelete() {
    return referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant) ? !this.invoice?.paymentParts?.length : !this.invoice
  }

  @computed
  get isBlockedByWaitingPayment() {
    return this.paymentState?.id === PaymentStateEnum.toPay && this.state?.id === EstimateStateEnum.draft
  }
}
