import React, { FC } from 'react'
import { useStore } from '@utils/hooks'
import { useRouter } from 'next/router'
import routes from '@const/routes'

/**
 * Authentication HOC to enforce correct access to anonymous pages
 */
const withAnon = (Page: FC) => {
  return (props => {
    const { store } = useStore()
    const router = useRouter()
    if (typeof window !== 'undefined') {
      if (store.meStore?.data?.customer) {
        router?.replace(routes.mon_compte.uri)
        return null
      }
      return <Page {...props} />
    }
    return <Page {...props} />
  }) as FC
}

export default withAnon
