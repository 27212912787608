import React, { FC } from 'react'
import { FormHelperText, InputLabel, OutlinedInput, Select } from '@mui/material'
import themeColor from '@const/colors.json'
import { withStyles } from '@mui/styles'
// color prop only supports material-ui values 'primary' | 'secondary'
const StyledInputLabel = withStyles({
  root: {
    color: themeColor.black2,
    marginBottom: 20,
    fontWeight: 'bold'
  }
})(InputLabel)

// eslint-disable-next-line no-unused-vars
type onChangeFunc = (event: any, child?: any) => void

interface Props {
  id: string
  label: string
  name: string
  children?: any
  onChange?: onChangeFunc
  type?: string
  placeholder?: string
  helper?: string
  helperId?: string
  value?: string
  select?: boolean
  error?: boolean
}

const CustomInput: FC<Props> = ({ id, label, helper, helperId, error, select, children, ...props }) => {
  const StyledFormHelperText = withStyles({
    root: {
      color: error ? 'red' : themeColor.grey1
    }
  })(FormHelperText)

  const StyledInput = withStyles({
    root: {
      backgroundColor: 'white',
      color: themeColor.grey1
    },
    notchedOutline: {
      //borderColor: error ? 'red' : themeColor.grey1
    }
  })(OutlinedInput)

  const getInput = () => {
    const InputElement = <StyledInput id={id} aria-describedby={helperId} {...props} className="h-[50px]" />

    if (select) {
      return (
        <Select id={id} aria-describedby={helperId} input={InputElement} displayEmpty {...props}>
          {children}
        </Select>
      )
    } else {
      return InputElement
    }
  }

  return (
    <div className="flex flex-1 flex-col">
      <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
      {getInput()}
      {helper && <StyledFormHelperText id={helperId}>{helper}</StyledFormHelperText>}
    </div>
  )
}

export default CustomInput
