import React, { FC } from 'react'
import { CTA, Cover, FlatButton } from '@components/ui'
import { Typography } from '@mui/material'
import club from '@assets/images/img-bg-club-cropped@2x.jpg'
import routes from '@const/routes'
import content from '@const/club.json'
import { withProps } from '@utils/hoc'
import colors from '@const/colors.json'
import { withStyles } from '@mui/styles'

const { title, firstLine, secondLine, button } = content

const Club: FC = () => {
  const Flag = withStyles({
    root: {
      color: '#009EE0',
      backgroundColor: 'rgba(0,158,224,0.15)',
      height: '40px',
      textAlign: 'center',
      fontWeight: 'bold'
    }
  })(Typography)

  const Advertise = withStyles({
    root: {
      fontWeight: 600,
      fontSize: '1.25rem',
      fontFamily: 'Newsreader'
    }
  })(Typography)

  return (
    <div className="flex min-h-[350px] flex-row items-stretch justify-between sm:min-h-full">
      <div className="bg-super-ya-blueclub p-[20px] sm:max-h-[270px] mobile:flex-grow">
        <div className="flex h-full max-w-[635px] flex-col items-start justify-between rounded-[4px] bg-white px-[10px] py-[20px] sm:pl-[30px] sm:pr-[70px]">
          <Flag className="flex min-w-[155px] flex-col items-center justify-center">{title}</Flag>
          <Advertise className="flex flex-col">
            <span>{firstLine}</span>
            <span className="text-super-ya-blue3">{secondLine}</span>
          </Advertise>
          <CTA
            wrapper={withProps(FlatButton, {
              variant: 'contained',
              color: 'primary',
              className: 'min-w-[185px] capitalize'
            })}
            url={routes.comment_ca_marche.uri}
            label={button}
            hover={colors.white}
          />
        </div>
      </div>
      <Cover className="hidden h-[270px] flex-grow px-[20px] py-[20px] sm:flex lg:min-w-[500px]" src={club.src} />
    </div>
  )
}

export default Club
