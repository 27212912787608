import { AbstractItem } from '@core/models/abstractItem'
import { Price } from '@common/models/price/price'
import { Customer } from '@common/models/customer/customer'
import { computed, makeObservable } from 'mobx'
import { Estimate } from '@common/models/estimate/estimate'
import { Invoice } from '@common/models/invoice/invoice'
import { Prices } from '@common/models/estimate/subscriptionPrice'
import { Warehouse } from '@common/models/warehouse/warehouse'

export class Credit extends AbstractItem {
  ref: string
  customer: Customer
  validationDate: Date
  estimate: Estimate
  remainingToUse: Price
  isCustom: boolean
  comment: string
  invoice: Invoice
  prices: Prices
  returnWarehouse: Warehouse
  withSelection: boolean

  constructor(data?: Partial<Credit>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }

  @computed
  get isValidated() {
    return !!this.validationDate
  }
}
