import { withStyles } from '@mui/styles'
import { Button } from '@mui/material'

export default withStyles({
  root: {
    borderRadius: 2,
    boxShadow: '0 20px 40px 0 rgba(35,51,58,0.1)',
    height: '50px'
  }
})(Button)
