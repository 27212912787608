import { FC, PropsWithChildren } from 'react'
import { Box } from '@mui/material'

const StepNavbarRoot: FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <Box id="superya-header" component="header" className="relative top-0 z-50 h-[110px] bg-super-ya-blue3 mobile:h-auto">
      <div className="mx-auto h-full lg:min-w-[1024px] 2xl:min-w-[1280px]">{children}</div>
    </Box>
  )
}

export default StepNavbarRoot
