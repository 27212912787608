import React, { FC } from 'react'
import { StepConnector as MuiStepConnector, StepLabel as MuiStepLabel, Stepper as MuiStepper, Step, StepIconProps, stepConnectorClasses, stepLabelClasses } from '@mui/material'
import clsx from 'clsx'
import { StepType } from 'types/step'
import navbarSteps from '@const/steps.json'
import { useSteps } from '@utils/hooks'
import { makeStyles, withStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#59BFEA'
  },
  active: {
    color: 'white'
  },
  circle: {
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: 'white'
  }
})

const StepIcon: FC<StepIconProps> = ({ active, completed }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? <div className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  )
}

const StepLabel = withStyles({
  label: {
    maxHeight: '40px',
    color: '#59BFEA',
    [`&.${stepLabelClasses.active}`]: {
      color: 'white'
    },
    [`&.${stepLabelClasses.completed}`]: {
      color: 'white'
    }
  },
  active: {},
  completed: {}
})(MuiStepLabel)

const StepConnector = withStyles({
  alternativeLabel: {
    top: 2,
    left: 'calc(-50% + 2.5px)',
    right: 'calc(50%)'
  },
  active: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'white'
    }
  },
  completed: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'white'
    }
  },
  line: {
    borderColor: '#59BFEA',
    borderTopWidth: 1,
    borderRadius: 1
  }
})(MuiStepConnector)

const StepperRoot = withStyles({
  root: {
    backgroundColor: 'rgb(0, 158, 224)',
    paddingRight: 0,
    paddingLeft: 0
  }
})(MuiStepper)

const Stepper: FC = () => {
  const [steps, activeStep] = useSteps(navbarSteps as Array<StepType>)

  return (
    <StepperRoot alternativeLabel activeStep={activeStep} orientation="horizontal" connector={<StepConnector />} className="md:min-w-[526px]">
      {steps.map(step => (
        <Step key={step.key}>
          <StepLabel StepIconComponent={StepIcon}>{step.label}</StepLabel>
        </Step>
      ))}
    </StepperRoot>
  )
}

export default Stepper
