import { FC, PropsWithChildren } from 'react'
import Logo from './sections/Logo'
import LogoIcon from '@assets/images/svg/logo-color.svg'

const NavbarRoot: FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <>
      <header id="superya-header" className="relative top-0 z-50 h-[110px] responsive:bg-white">
        <div className="absolute z-0 h-[175px] w-[175px] rounded-br-[175px] bg-white responsive:bg-transparent">
          <div className="relative left-10 top-3 mobile:left-3 mobile:top-4">
            <Logo icon={<LogoIcon />} />
          </div>
        </div>
        <div className="mx-auto lg:min-w-[1024px] 2xl:min-w-[1280px]">{children}</div>
      </header>
    </>
  )
}

export default NavbarRoot
