import { AbstractItem } from '@core/models/abstractItem'
import { makeObservable, observable } from 'mobx'

export class AcceptanceCondition extends AbstractItem {
  label: string
  @observable
  idx: number

  constructor(data?: Partial<AcceptanceCondition>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}
