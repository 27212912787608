import React from 'react'
import classNames from 'classnames'
import { SkTooltip } from '@core/components/tooltip/skTooltip'
import { SkIconUilBan, SkIconUilCheck, SkIconUilExclamationCircle } from '@core/global/icons'

interface IProps {
  id?: string
  children: React.ReactNode
  success?: boolean
  danger?: boolean
  warning?: boolean
  roundedFull?: boolean
  pink?: boolean
  className?: string
  tooltip?: React.ReactNode
  withIcon?: boolean
  light?: boolean
  dataId?: string
}
export const SkBadge = (props: IProps) => {
  function getBadge() {
    const getIcon = () => {
      if (props.success) return <SkIconUilCheck />
      if (props.warning) return <SkIconUilExclamationCircle />
      if (props.danger) return <SkIconUilBan />
      return <SkIconUilExclamationCircle />
    }

    const getId = () => {
      if (props.success) return 'badge-success'
      if (props.danger) return 'badge-danger'
      if (props.warning) return 'badge-warning'
      if (props.pink) return 'badge-pink'
      return 'badge-normal'
    }

    return (
      <span
        data-id={props.dataId ?? `SkBadge_${props.id ?? ''}_${getId()}`}
        className={classNames(
          'flex inline-flex items-center justify-center rounded font-semibold',
          {
            'px-3 py-1 text-xs': !props.light,
            'p-3': props.light,
            'rounded-full': props.roundedFull,
            'bg-pink-200 text-pink-800': props.pink && !props.light,
            'bg-pink-100': props.pink && props.light,
            'bg-green-200 text-green-800': props.success && !props.light,
            'bg-green-100': props.success && props.light,
            'bg-red-200 text-red-800': props.danger && !props.light,
            'bg-red-100': props.danger && props.light,
            'bg-yellow-200 text-yellow-800': props.warning && !props.light,
            'bg-yellow-100 ': props.warning && props.light,
            'bg-blue-200 text-blue-800': !props.success && !props.danger && !props.warning && !props.pink && !props.light,
            'bg-blue-100': !props.success && !props.danger && !props.warning && !props.pink && props.light
          },
          props.className
        )}
      >
        {props.withIcon && <span className="pr-2">{getIcon()}</span>}
        {props.children}
      </span>
    )
  }

  if (props.tooltip) {
    return (
      <SkTooltip withBorderInfo label={props.tooltip}>
        {getBadge()}
      </SkTooltip>
    )
  }
  return getBadge()
}
