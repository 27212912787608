import React, { FC, useContext } from 'react'
import { useRoutes, useStore } from '@utils/hooks'
import { useRouter } from 'next/router'
import routes from '@const/routes'
import { RedirectContext } from '@utils/context/redirectContext'

/**
 * Authentication HOC to restrict user access to authenticated pages
 */
const withAuth = (Page: FC) => {
  return (props => {
    const { store, isLoading, isError } = useStore()
    const { setRedirectTo } = useContext(RedirectContext)
    const router = useRouter()
    if (typeof window !== 'undefined') {
      if (store && !store.meStore?.data && !isLoading && !isError) {
        setRedirectTo(useRoutes(routes).find(route => route.uri === router.asPath)?.uri ?? '/')
        router.replace(routes.connexion.uri)
        return null
      }
      return <Page {...props} />
    }
    return null
  }) as FC
}

export default withAuth
