import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable, observable } from 'mobx'
import { AbstractAddress } from '@core/models/abstractAddress'
import { Customer } from '@common/models/customer/customer'

export class CustomerAddress extends AbstractLabel {
  @observable
  address: AbstractAddress
  customer: Customer
  isDeliverable: boolean
  @observable
  withFreeShipping: boolean

  constructor(data?: Partial<CustomerAddress>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}
