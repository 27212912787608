import { action, computed, makeObservable, observable } from 'mobx'
import { Customer } from '@common/models/customer/customer'
import { EstimateLine } from '@common/models/estimateLine/estimateLine'
import { Warehouse } from '@common/models/warehouse/warehouse'
import { AbstractLabel } from '@core/models/abstractLabel'
import { InvoicePlan } from '@common/models/invoicePlan/invoicePlan'
import { Discount } from '@common/models/discount/discount'
import { Price } from '@common/models/price/price'
import { TaxMention } from '@common/models/taxMention/taxMention'
import { User } from '@common/models/user/user'
import { AbstractAttributeValues } from '@common/models/abstractAttributeValue/abstractAttributeValues'
import { referentialStore } from '@common/models/referential/referentialStore'
import { DiscountCodeEnum, EstimateStateEnum, EstimateTypeEnum } from '@common/global/enums'
import { AbstractLabelObservable } from '@core/models/abstractLabelObservable'
import { SubscriptionPrice } from '@common/models/estimate/subscriptionPrice'
import { Document } from '@core/models/document'
import { Tools } from '@core/utils/tools'
import { Contact } from '@common/models/contact/contact'
import { AbstractItem, AbstractUi } from '@core/models/abstractItem'
import { AcceptanceCondition } from '@common/models/estimateQuote/acceptanceCondition'

export class AbstractEstimate extends AbstractAttributeValues {
  originalId: number
  @observable
  ref: string
  @observable
  comments: EstimateComment[]
  @observable
  state: AbstractLabelObservable<EstimateStateEnum>
  @observable
  customer: Customer
  @observable
  lines: EstimateLine[]
  @observable
  warehouse: Warehouse
  @observable
  discount: Discount
  @observable
  totalPriceExcludingTaxesWithoutDiscount: Price
  @observable
  totalPriceExcludingTaxesWithDiscount: Price
  @observable
  totalDiscountRate: number
  @observable
  totalPriceIncludingTaxes: Price
  @observable
  totalPriceIncludingTaxesWithShipping: Price
  @observable
  totalTaxes: Price
  @observable
  totalShippingPriceIncludingTaxes: Price
  @observable
  canValidate: boolean
  validationDate: Date
  @observable
  invoicePlan: InvoicePlan
  type: AbstractLabel
  @observable
  category: AbstractLabel
  @observable
  description: string
  @observable
  validityPeriod: string
  @observable
  dueDate: Date
  @observable
  isTaxExempt: boolean = false
  @observable
  taxMention: TaxMention
  @observable
  commercial: User
  @observable
  subscriptionPrices: SubscriptionPrice[]
  documents: Document[]
  @observable
  specialMention: string
  @observable
  acceptanceConditions: AcceptanceCondition[]
  @observable
  recipients: EstimateRecipient[]
  @observable
  businessDealLabels: string
  @observable
  withDirectDebit: boolean
  @observable
  contractNumber: string
  @observable
  startDate: Date
  @observable
  endDate: Date
  @observable
  invoicingStartDate: Date
  @observable
  invoicingEndDate: Date

  ui = new AbstractEstimateUi()

  constructor(data?: Partial<AbstractEstimate>) {
    super(data)
    Object.assign(this, data)
    if (this.state) this.state = new AbstractLabelObservable(this.state)
    if (this.category) this.category = new AbstractLabelObservable(this.category)
    if (this.customer) this.customer = new Customer(this.customer)
    if (this.warehouse) this.warehouse = new Warehouse(this.warehouse)
    if (this.invoicePlan) this.invoicePlan = new InvoicePlan(this.invoicePlan)
    if (this.discount) this.discount = new Discount(this.discount)
    if (this.taxMention) this.taxMention = new TaxMention(this.taxMention)
    if (this.commercial) this.commercial = new User(this.commercial)
    if (this.lines) {
      this.lines.forEach((item, index) => {
        this.lines[index] = new EstimateLine(item)
      })
    }
    if (this.subscriptionPrices) {
      this.subscriptionPrices.forEach((item, index) => {
        this.subscriptionPrices[index] = new SubscriptionPrice(item)
      })
    }
    if (this.documents) {
      this.documents.forEach((item, index) => {
        this.documents[index] = new Document(item)
      })
    }
    if (this.acceptanceConditions) {
      this.acceptanceConditions.forEach((item, index) => {
        this.acceptanceConditions[index] = new AcceptanceCondition(item)
      })
    }
    if (this.recipients) {
      this.recipients.forEach((item, index) => {
        this.recipients[index] = new EstimateRecipient(item)
      })
    }
    if (Tools.isNullOrUndefined(this.withDirectDebit)) this.withDirectDebit = false
  }

  @computed
  get invoice() {
    if (this.invoicePlan?.invoices?.length) return this.invoicePlan.invoices[0]
    return null
  }

  @computed
  get lastInvoice() {
    if (this.invoicePlan?.invoices?.length) {
      return this.invoicePlan.invoices.slice().sort((x, y) => y.id - x.id)[0]
    }
    return null
  }

  @action
  setWarehouseDefaultOrFirst(warehouses: Warehouse[], lastWarehouseIdUsed: number) {
    if (!this.warehouse && warehouses.length) {
      this.warehouse = warehouses.find(x => x.id === lastWarehouseIdUsed) ?? warehouses[0]
    }
  }

  @action
  setDiscount = (percentage: number) => {
    if (!percentage) {
      this.discount = null
      return
    }
    if (!this.discount) this.discount = new Discount()
    this.discount.type = referentialStore.data.discountTypes.find(x => x.code === DiscountCodeEnum.globalDiscount)
    this.discount.percentage = percentage
  }

  @computed
  get isSubscription() {
    return this.type.id === EstimateTypeEnum.subscription
  }

  @computed
  get subscriptionPriceFirst() {
    return this.subscriptionPrices && this.subscriptionPrices[0]
  }
}

class EstimateComment {
  state: string
  text: string
}

export class AbstractEstimateUi extends AbstractUi {
  @observable
  $cancelLoading = false

  constructor() {
    super()
    makeObservable(this)
  }
}

export class EstimateRecipient extends AbstractItem {
  contact: Contact

  constructor(data: Partial<EstimateRecipient>) {
    super(data)
    Object.assign(this, data)
  }
}
