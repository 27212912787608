import React, { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import { Head } from '@components/common'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import ConsentCookies, { refresh } from '@skazy/react-rgpd'
import { enableStaticRendering } from 'mobx-react-lite'
import theme from '@assets/theme'
import { setDefault } from '@common/global/default'
import { StoreProvider } from '@utils/context'
import { CacheProvider, EmotionCache } from '@emotion/react'
enableStaticRendering(typeof window === 'undefined')
import 'dayjs/locale/fr'
///// import styles
import 'antd/dist/antd.css'
import '../styles/variables.less'
import 'tailwindcss/tailwind.css'
import '../styles/index.scss'
import RedirectProvider from '@utils/context/redirectContext'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import createEmotionCache from '@utils/helpers/createEmotionCache'

const clientSideEmotionCache = createEmotionCache()

const MyApp: FC<AppProps & { emotionCache: EmotionCache }> = ({ Component, emotionCache = clientSideEmotionCache, pageProps }) => {
  const router = useRouter()

  const { events } = router

  const onRouteComplete = () => {
    // refresh consent cookie
    refresh()
  }

  setDefault()

  useEffect(() => {
    // router events
    events.on('routeChangeComplete', onRouteComplete)

    return () => {
      events.off('routeChangeComplete', onRouteComplete)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      <StoreProvider>
        <RedirectProvider>
          <Head />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                <ConsentCookies
                  customText={{
                    middleBarHead: 'Nous utilisons des Cookies',
                    alertBig:
                      'Des cookies sont utilisés pour conserver vos informations de connexion et réaliser des statistiques en vue d’optimiser les services du site et d’adapter nos contenus et nos campagnes publicitaires pour personnaliser et améliorer votre expérience.Pour en savoir plus, consultez la politique de confidentialité.',
                    alertBigPrivacy:
                      'Des cookies sont utilisés pour conserver vos informations de connexion et réaliser des statistiques en vue d’optimiser les services du site et d’adapter nos contenus et nos campagnes publicitaires pour personnaliser et améliorer votre expérience.Pour en savoir plus, consultez la politique de confidentialité.',
                    acceptAll: 'Accepter',
                    allowAll: 'Accepter',
                    denyAll: 'Refuser'
                  }}
                  initialize={{
                    hashtag: '#cookie-banner',
                    privacyUrl: '/mentions-legales',
                    showIcon: false
                  }}
                  jobs={['googletagmanager', 'googlemapsembed']}
                  user={{
                    googletagmanagerId: 'GTM-PPPGKZ5'
                  }}
                />
                <CssBaseline />
                <div className="flex min-h-screen flex-col justify-between">
                  <Component {...pageProps} />
                </div>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </RedirectProvider>
      </StoreProvider>
    </CacheProvider>
  )
}

export default MyApp
