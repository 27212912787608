import { AbstractItem } from '@core/models/abstractItem'
import { Frequency } from '@common/models/frequency/frequency'
import { Price } from '@common/models/price/price'
import { makeObservable, observable } from 'mobx'

export class SubscriptionPrice extends AbstractItem {
  @observable
  invoiceFrequency: Frequency
  @observable
  prices: Prices
  pricesWithoutExpired: Prices

  constructor(data: Partial<SubscriptionPrice>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
    if (this.invoiceFrequency) this.invoiceFrequency = new Frequency(this.invoiceFrequency)
    this.prices = new Prices(this.prices)
  }

  getPrices(withExpiredLines: boolean = false) {
    return withExpiredLines ? this.prices : this.pricesWithoutExpired
  }
}

export class Prices {
  @observable
  totalPriceExcludingTaxesWithDiscount: Price
  @observable
  totalPriceExcludingTaxesWithoutDiscount: Price
  @observable
  totalPriceIncludingTaxes: Price
  @observable
  totalTaxes: Price

  constructor(data?: Partial<Prices>) {
    Object.assign(this, data)
    makeObservable(this)
  }
}
