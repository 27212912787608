import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { StepNavbar } from '../Navbar'
import BaseLayout from './BaseLayout'
import { withProps } from '@utils/hoc'
import { TitleField } from '@components/ui'

type StepLayoutProps = {
  stepper?: ReactNode
  title?: ReactNode
  buttons?: ReactNode
  footer?: ReactNode
  additional?: ReactNode
}

const StepLayout: FC<PropsWithChildren<StepLayoutProps>> = ({ stepper, title, buttons, footer, additional, children }) => {
  const Layout = withProps(BaseLayout, {
    navbar: <StepNavbar stepper={stepper} buttons={buttons} />,
    ...{ footer }
  })

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mt-[30px]">
          <TitleField>
            <span className="flex space-x-[15px]">{title}</span>
          </TitleField>
        </div>
        {children}
      </div>
      {additional && <div className="container mx-auto mt-[40px] flex flex-col items-stretch justify-between space-y-[40px]">{additional}</div>}
    </Layout>
  )
}

export default StepLayout
