import React, { FC, PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import { withStyles } from 'tss-react/mui'

type CoverProps = {
  className: string
  src: string
}

const Cover: FC<PropsWithChildren<CoverProps>> = ({ className, src, children }) => {
  const Wrapper = withStyles(Box, {
    root: {
      backgroundImage: `url(${src})`,
      backgroundSize: 'cover'
    }
  })

  return <Wrapper className={className}>{children}</Wrapper>
}

export default Cover
