import { FC } from 'react'
import { Typography } from '@mui/material'
import { Items } from 'types/app'
import CartIcon from '@assets/images/svg/ico-panier.svg'
import TruckIcon from '@assets/images/svg/ico-truck.svg'
import ArchiveIcon from '@assets/images/svg/ico-gaspillage.svg'
import CalendarIcon from '@assets/images/svg/ico-calendar.svg'

interface ComponentProps {
  classes?: string
}

const Reinsurance: FC<ComponentProps> = ({ classes }) => {
  const items: Array<Items> = [
    {
      icon: <CartIcon />,
      text: ['24 recettes au choix chaque semaine']
    },
    {
      icon: <TruckIcon />,
      text: ['Livraison sur Nouméa et son agglomération']
    },
    {
      icon: <ArchiveIcon />,
      text: ['Utilisez votre gamelle pour éviter le gaspillage']
    },
    {
      icon: <CalendarIcon />,
      text: ['Arrêtez quand vous voulez, c’est sans engagement']
    }
  ]

  return (
    <div className={`grid grid-cols-2 gap-4 md:grid-cols-4 ${classes}`}>
      {items.map((item: Items, index: number) => (
        <div className="grid text-center xl:grid-cols-12 xl:text-left" key={index}>
          <div className="col-span-12 flex justify-center xl:col-span-2">{item.icon}</div>
          <div className="xl:col-span-10 xl:max-w-[200px]">
            {item.text.map((line, index) => (
              <Typography variant="body1" component="p" key={index}>
                {line}
              </Typography>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

Reinsurance.defaultProps = {
  classes: 'py-5'
}

export default Reinsurance
