import React, { Context, FC, PropsWithChildren, createContext, useState } from 'react'

export const RedirectContext: Context<any> = createContext({
  redirectTo: null,
  setRedirectTo: () => {}
})

const RedirectProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [redirectTo, setRedirectTo] = useState<string | null>(() => null)

  return <RedirectContext.Provider value={{ redirectTo, setRedirectTo }}>{children}</RedirectContext.Provider>
}

export default RedirectProvider
