import { SkIconUilShoppingCart } from '@core/global/icons'
import { Http } from '@core/api/http'
import { ActionInProgress } from '@core/store/eventStore'
import moment from 'moment'
import { coreStore } from '@core/store/coreStore'
import { ShippingInfo } from '@common/models/shippingInfo/shippingInfo'
import { Planning } from '@common/models/planning/planning'
import { Package } from '@common/models/package/package'
import { Variant } from '@common/models/variant/variant'
import { EstimateLine } from '@common/models/estimateLine/estimateLine'
import { CodePermissionEnum, EstimateStateEnum, EstimateTypeEnum, PaymentStateEnum } from '@common/global/enums'
import { AbstractEstimate } from '@common/models/estimate/abstractEstimate'
import { Estimate } from '@common/models/estimate/estimate'
import { Payment } from '@common/models/payment/payment'
import { Discount } from '@common/models/discount/discount'
import { Query } from '@core/global/interfaces'
import { ApiStore } from '@core/api/apiStore'
import { referentialStore } from '@common/models/referential/referentialStore'
import { Price } from '@common/models/price/price'
import React from 'react'
import { Credit } from '@common/models/credit/credit'
import { Message } from '@core/service/message'
import { ModalService } from '@core/service/modal'
import { action, observable, runInAction } from 'mobx'
import { Customer } from '@common/models/customer/customer'
import { FieldsNaming } from '@common/global/fieldsNaming'
import { CreditStore } from '@common/models/credit/creditStore'

export class EstimateStore<T extends AbstractEstimate> extends ApiStore<T> {
  @observable
  canValidate: boolean
  startDate: Date
  @observable
  $validateLoading: boolean
  @observable
  $addCreditLoading: boolean
  @observable
  $useCreditLoading: boolean
  @observable
  $sendConfirmationMailLoading: boolean
  @observable
  $downloadInvoiceLoading: boolean
  @observable
  $downloadAllInvoicesLoading: boolean
  @observable
  $duplicateLoading: boolean
  @observable
  $reviseLoading: boolean
  @observable
  $downloadReceiptLoading: boolean
  @observable
  $forceAndDownloadInvoiceLoading: boolean
  @observable
  $downloadEstimateLoading: boolean
  @observable
  $addPaymentLoading: boolean
  @observable
  $addVariantLoading: boolean
  @observable
  $addLinesLoading: boolean
  @observable
  addCreditLoading: boolean
  @observable
  $deleteDiscountLoading: boolean
  @observable
  $validateSyncLoading: boolean
  @observable
  $setShippingInfoLoading: boolean
  @observable
  $lastDraftLoading: boolean
  @observable
  $setDiscountLoading: boolean

  constructor() {
    super(
      coreStore.paramAppStore.apiUrl,
      '/estimate',
      // @ts-ignore
      Estimate,
      FieldsNaming.estimate.genre,
      FieldsNaming.estimate.objectName.singular,
      FieldsNaming.estimate.itemName.singular.toLowerCase(),
      FieldsNaming.estimate.thisItemName.singular.toLowerCase(),
      SkIconUilShoppingCart,
      '/sale/estimates',
      'route'
    )
    this.canValidate = this.canEdit || coreStore.meStore.hasPermission(CodePermissionEnum.action_validate_and_invoice_estimate)
    this.withMessageSave = false
    this.canBePathPublicIfNotMe = true
    this.filterInit = { type: { id: EstimateTypeEnum.estimate } }
    this.initObjectWhenCreate = { type: { id: EstimateTypeEnum.estimate } }
    this.searchTypeLight = 'light'
  }

  async $deleteConfirm(item: T) {
    if (item instanceof Estimate && !item.canDelete) {
      ModalService.showError(
        <>
          <div className="pb-3 font-bold">Vous ne pouvez pas supprimer {FieldsNaming.estimate.itemName.singular.toLowerCase()} qui a déjà une facture.</div>
          <div>Veuillez faire un avoir pour annuler une partie ou la totalité {FieldsNaming.estimate.ofThisItemName.singular.toLowerCase()}.</div>
        </>
      )
      throw 'Skazy: action impossible'
    }
    return await super.$deleteConfirm(item)
  }

  @action
  async $validate(item: T, onSuccess?: () => void, onError?: () => void) {
    try {
      this.$validateLoading = true
      return await Http.$put(`${this.baseUrl}/${item.id}/validate`, item).then(data => {
        coreStore.eventStore.addActionInProgress(
          new ActionInProgress({
            uuid: data as unknown as string,
            label: 'Validation du devis',
            date: moment(),
            onSuccess: onSuccess,
            onError: onError
          })
        )
        return data as string
      })
    } finally {
      runInAction(() => (this.$validateLoading = false))
    }
  }

  @action
  async $validateSync(item: T) {
    try {
      this.$validateSyncLoading = true
      return await Http.$put(`${this.baseUrl}/${item.id}/validate-sync`, item)
    } finally {
      runInAction(() => (this.$validateSyncLoading = false))
    }
  }

  @action
  async $lastDraft() {
    try {
      this.$lastDraftLoading = true
      return await Http.$get<Estimate>(`${this.baseUrl}/last-draft`)
    } finally {
      runInAction(() => (this.$lastDraftLoading = false))
    }
  }

  @action
  async $setShippingInfo(item: T, shippingInfo: ShippingInfo) {
    try {
      this.$setShippingInfoLoading = true
      return await Http.$put<ShippingInfo>(`${this.baseUrl}/${item.id}/shipping-info`, shippingInfo)
    } finally {
      runInAction(() => (this.$setShippingInfoLoading = false))
    }
  }

  @action
  async $setDiscount(item: T, discountCode: string) {
    try {
      this.$setDiscountLoading = true
      return await Http.$put<Discount>(`${this.baseUrl}/${item.id}/set-discount`, new Discount({ code: discountCode }), { hideGlobalMessageError: true })
    } finally {
      runInAction(() => (this.$setDiscountLoading = false))
    }
  }

  @action
  async $deleteDiscount(item: T) {
    try {
      this.$deleteDiscountLoading = true
      return await Http.$delete(`${this.baseUrl}/${item.id}/discount`)
    } finally {
      runInAction(() => (this.$deleteDiscountLoading = false))
    }
  }

  @action
  async $cancelConfirm(item: T) {
    try {
      await ModalService.showConfirm(
        `Vous êtes sur le point d’annuler ${FieldsNaming.estimate.anItemName.singular.toLowerCase()}.\nConfirmez-vous ?`,
        `Annuler ${FieldsNaming.estimate.anItemName.singular.toLowerCase()}`
      )
      item.ui.$cancelLoading = true
      await Http.$put(`${this.baseUrl}/${item.id}/cancel`).then(() => this.setEventStoreModified())
      Message.success('Annulation effectuée')
    } finally {
      runInAction(() => {
        item.ui.$cancelLoading = false
      })
    }
  }

  action

  async $rejectConfirm(item: T) {
    try {
      await ModalService.showConfirm(
        `Vous êtes sur le point de rejeter ${FieldsNaming.estimate.anItemName.singular.toLowerCase()}.\nConfirmez-vous ?`,
        `Rejeter ${FieldsNaming.estimate.anItemName.singular.toLowerCase()}`
      )
      item.ui.$cancelLoading = true
      await Http.$put(`${this.baseUrl}/${item.id}/reject`).then(() => this.setEventStoreModified())
      await this.$reload(item)
      Message.success('Rejet effectué')
    } finally {
      runInAction(() => {
        item.ui.$cancelLoading = false
      })
    }
  }

  @action
  async $restoreConfirm(item: T) {
    try {
      await ModalService.showConfirm(
        `Vous êtes sur le point de rétablir ${FieldsNaming.estimate.anItemName.singular.toLowerCase()}.\nConfirmez-vous ?`,
        `Rétablir ${FieldsNaming.estimate.thisItemName.singular.toLowerCase()}`
      )
      item.ui.$cancelLoading = true
      await Http.$put(`${this.baseUrl}/${item.id}/restore`).then(() => this.setEventStoreModified())
      Message.success('La commande est de nouveau effective')
      await this.$reload(item)
    } finally {
      runInAction(() => {
        item.ui.$cancelLoading = false
      })
    }
  }

  @action
  $addVariant = async (data: { estimate: T; planning?: Planning; pack?: Package; variant: Variant; tagMustMatch?: boolean }) => {
    try {
      this.$addVariantLoading = true
      await Http.$put(`${this.baseUrl}/${data.estimate.id}/add-variant`, {
        planningId: data.planning?.id,
        packageId: data.pack?.id,
        variantId: data.variant.id,
        tagMustMatch: data.tagMustMatch
      })
    } finally {
      runInAction(() => (this.$addVariantLoading = false))
    }
  }

  @action
  $addLines = async (estimate: T, estimateLines: EstimateLine[]) => {
    try {
      this.$addLinesLoading = true
      return await Http.$put(`${this.baseUrl}/${estimate.id}/add-lines`, estimateLines)
    } finally {
      runInAction(() => (this.$addLinesLoading = false))
    }
  }

  @action
  $addPayment = async (estimate: T, payment: Payment) => {
    try {
      this.$addPaymentLoading = true
      return await Http.$post(`${this.baseUrl}/${estimate.id}/payment`, payment)
    } finally {
      runInAction(() => (this.$addPaymentLoading = false))
    }
  }

  @action
  $useCredit = async (estimate: T, credit: Price) => {
    try {
      this.$useCreditLoading = true
      await ModalService.showConfirm(`Confirmez-vous l‘utilisation de l’avoir de ${credit.amount} ${credit.currency.label} ?`, 'Utiliser l’avoir')
      return await Http.$post(`${this.baseUrl}/${estimate.id}/use-credit`)
    } catch {
      // catch silent
    } finally {
      runInAction(() => (this.$useCreditLoading = false))
    }
  }

  @action
  $addCredit = async (estimate: T) => {
    try {
      this.$sendConfirmationMailLoading = true
      const data = await Http.$post<Credit>(`${this.baseUrl}/${estimate.id}/credit`)
      new CreditStore().invalidateQueries()
      return data
    } finally {
      runInAction(() => (this.$sendConfirmationMailLoading = false))
    }
  }

  $searchValidated = async (query?: Query<AbstractEstimate>) => {
    if (!query) query = {}
    if (!query.params) query.params = {}
    query.params = { ...query.params, filterInit: { state: { id: EstimateStateEnum.validated } } }
    return await this.$search(query)
  }

  @action
  async $sendConfirmationMail(estimate: T) {
    try {
      this.$sendConfirmationMailLoading = true
      return await Http.$post(`${this.baseUrl}/${estimate.id}/send-confirmation-mail`).then(() => Message.success('L’email a bien été envoyé'))
    } finally {
      runInAction(() => (this.$sendConfirmationMailLoading = false))
    }
  }

  @action
  async $downloadEstimate(estimate: T) {
    try {
      this.$downloadEstimateLoading = true
      await this.$download(estimate, `${estimate.id}/pdf`, 'application/pdf', 'pdf', null, false, `Devis ${estimate.ref} - ${estimate.customer.label}`)
    } finally {
      runInAction(() => (this.$downloadEstimateLoading = false))
    }
  }

  @action
  async $forceAndDownloadInvoice(estimate: T) {
    try {
      this.$forceAndDownloadInvoiceLoading = true
      await this.$download(
        estimate,
        `${estimate.id}/invoice-pdf`,
        'application/pdf',
        'pdf',
        { params: { forceInvoice: true } },
        false,
        `Facture ${estimate.invoice != null ? estimate.invoice.ref : ''} - ${estimate.customer.label}`
      )
      await this.$reload(estimate)
    } finally {
      runInAction(() => (this.$forceAndDownloadInvoiceLoading = false))
    }
  }

  @action
  async $downloadReceipts(estimate: T) {
    try {
      this.$downloadReceiptLoading = true
      await this.$download(estimate, `${estimate.id}/receipt-pdf`, 'application/pdf', 'pdf', null, false, `Facture ${estimate.lastInvoice.ref} - ${estimate.customer.label}`)
    } finally {
      runInAction(() => (this.$downloadReceiptLoading = false))
    }
  }

  @action
  async $downloadInvoice(estimate: T) {
    if (!estimate.lastInvoice) return Promise.reject()
    try {
      const InvoiceStore = (await import('@common/models/invoice/invoiceStore')).InvoiceStore
      this.$downloadInvoiceLoading = true
      await new InvoiceStore().$downloadInvoice(estimate.lastInvoice)
    } finally {
      runInAction(() => (this.$downloadInvoiceLoading = false))
    }
  }

  @action
  async $sendInvoicesSelectedByCurrentFilters() {
    await this.$sendInvoices()
  }

  @action
  async $sendInvoicesAndTotalForCustomersWithoutEmail() {
    const query = EstimateStore.buildQueryForInvoicesToPay()
    query.params.withReceipts = true
    query.params.customFilter = { customerWithoutEmail: true }
    await this.$sendInvoices(query)
  }

  @action
  async $addBusinessDealToEstimate(customer: Customer) {
    const query = EstimateStore.buildQueryForInvoicesToPay()
    query.params.filter.customer = { id: customer.id }
    await this.$downloadInvoices(query, `Factures client - ${customer.label}`)
  }

  $duplicate(estimateId: number, forType: EstimateTypeEnum = EstimateTypeEnum.estimate) {
    return Http.$put<T>(`${this.baseUrl}/${estimateId}/duplicate?for-type=${forType}`)
  }

  $revise(estimateId: number) {
    return Http.$put<T>(`${this.baseUrl}/${estimateId}/revise`)
  }

  $generateInvoice(estimateId: number) {
    return Http.$put<T>(`${this.baseUrl}/${estimateId}/create-invoice`)
  }

  $getAllRevisions(originalId: number) {
    return this.$all({ params: { customFilterInit: { versionsFromOriginal: originalId } } }) as unknown as Promise<Estimate[]>
  }

  $updateAllPrices() {
    return Http.$get<T>(`${this.baseUrl}/update-all-prices`)
  }

  private async $sendInvoices(query?: Query<Estimate>) {
    await Http.$post(`${this.baseUrl}/pdf/async`, undefined, this.getOptions(query ?? this.constructQueryFilterAndCustomFilterAccordingFilters(new Query<T>())))
    const contactEmail = referentialStore.data.tenantProperties.mailProperties?.replyTo
    Message.success(`Les factures vont être envoyées à ${contactEmail}`)
  }

  private async $downloadInvoices(query: Query<Estimate>, fileName: string) {
    try {
      this.$downloadAllInvoicesLoading = true
      await this.$download(null, '/pdf', 'application/pdf', 'pdf', query, false, fileName)
    } finally {
      runInAction(() => (this.$downloadAllInvoicesLoading = false))
    }
  }

  private static buildQueryForInvoicesToPay() {
    const query = new Query<Estimate>()
    query.params.filter = {
      type: { id: EstimateTypeEnum.estimate },
      paymentState: { id: PaymentStateEnum.toPay },
      state: { id: EstimateStateEnum.validated }
    }
    query.params.withSummaryByCustomer = true
    return query
  }
}
