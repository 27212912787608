import { useContext } from 'react'
import { StoreContext } from '@utils/context'

/**
 * Helper hooks that provides the gescom core store initialised
 */
const useStore = () => {
  return useContext(StoreContext)
}

export default useStore
