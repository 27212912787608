import React, { FC, PropsWithChildren, ReactNode } from 'react'
import StepNavbarRoot from './StepNavbarRoot'
import { useResponsive } from '@utils/hooks'
import Logo from './sections/Logo'
import LogoIcon from '@assets/images/svg/logo-white.svg'

type StepNavbarProps = {
  stepper?: ReactNode
  buttons?: ReactNode
}

const StepNavbar: FC<StepNavbarProps> = ({ stepper, buttons }) => {
  const mobile = useResponsive(540).responsive

  const Wrapper: FC<PropsWithChildren<any>> = mobile
    ? ({ children }) => (
        <div className="flex flex-col items-stretch justify-between">
          <div className="flex items-stretch justify-between">
            <Logo icon={<LogoIcon />} className="m-[20px]" />
            <div className="m-[20px]">{buttons}</div>
          </div>
          {children}
        </div>
      )
    : ({ children }) => (
        <div className="container mx-auto flex h-full items-center justify-between px-[15px] mobile:justify-center">
          <Logo icon={<LogoIcon />} />
          {children}
          <div className="mobile:hidden">{buttons}</div>
        </div>
      )

  return (
    <StepNavbarRoot>
      <Wrapper>{stepper}</Wrapper>
    </StepNavbarRoot>
  )
}

export default StepNavbar
