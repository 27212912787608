import React, { FC, PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { withStyles } from 'tss-react/mui'

type ContainProps = {
  className: string
  src: string
}

const Contain: FC<PropsWithChildren<ContainProps>> = ({ className, src, children }) => {
  const Wrapper = withStyles(Box, {
    root: {
      backgroundImage: `url(${src})`,
      backgroundSize: 'contain'
    }
  })

  return <Wrapper className={className}>{children}</Wrapper>
}

export default Contain
