import { FC } from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { usePageTitle } from '@utils/hooks'

interface ComponentProps {}

const Head: FC<ComponentProps> = () => {
  const router = useRouter()

  const { description, title } = usePageTitle()

  const canonical = 'https://superya.nc' + router.asPath

  return (
    <>
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{title !== '' ? title + ' | Super Ya' : 'Super Ya'}</title>
        <meta name="description" content={description} />
        {canonical && <link rel="canonical" href={canonical} />}
      </NextHead>
    </>
  )
}

export default Head
