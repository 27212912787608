import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { Footer } from '@components/common'

type LayoutProps = {
  navbar: ReactNode
  footer?: ReactNode
}

const BaseLayout: FC<PropsWithChildren<LayoutProps>> = ({ navbar, footer = <Footer />, children }) => {
  return (
    <>
      {navbar}
      <main>{children}</main>
      {footer}
    </>
  )
}

export default BaseLayout
