import React, { FC } from 'react'
import Link from 'next/link'

type LogoProps = {
  icon: React.ReactNode
  className?: string
}

const Logo: FC<LogoProps> = ({ icon, className }) => {
  return (
    <div className={`h-[50px] w-[50px] md:h-[80px] md:w-[80px] xl:h-[100px] xl:w-[100px] ${className}`}>
      <Link href="/" passHref={true} title="Retour accueil">
        {icon}
      </Link>
    </div>
  )
}

export default Logo
