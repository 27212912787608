import { AbstractItem } from '@core/models/abstractItem'
import { PaymentStateEnum, PaymentTypeEnum } from '@common/global/enums'
import { Price } from '@common/models/price/price'
import { computed, makeObservable, observable } from 'mobx'
import { AbstractLabelObservable } from '@core/models/abstractLabelObservable'
import { PaymentBankDeposit } from '@common/models/paymentBankDeposit/paymentBankDeposit'
import { Invoice } from '@common/models/invoice/invoice'
import { Estimate } from '@common/models/estimate/estimate'

export class Payment extends AbstractItem {
  @observable
  price: Price
  @observable
  type: AbstractLabelObservable
  @observable
  state: AbstractLabelObservable
  @observable
  paymentDate: Date
  @observable
  originator: string
  @observable
  debitCardOwner: string
  @observable
  bankName: string
  @observable
  creditCardType: string
  @observable
  checkNumber: string
  @observable
  userLabel: string
  @observable
  customerLabel: string
  @observable
  creditsLabel: string
  @observable
  invoiceNumber: string
  @observable
  invoices: Invoice[]
  @observable
  estimateNumber: string
  @observable
  estimates: Estimate[]
  @observable
  cancellationDate: Date
  @observable
  detail: string
  @observable
  bankDeposit: PaymentBankDeposit

  constructor(data?: Partial<Payment>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
    if (!this.type) this.type = new AbstractLabelObservable({ id: PaymentTypeEnum.bankCard })
    if (!this.state) this.state = new AbstractLabelObservable({ id: PaymentStateEnum.payed })
    if (this.bankDeposit) this.bankDeposit = new PaymentBankDeposit(this.bankDeposit)
    if (this.invoices) {
      this.invoices.forEach((item, index) => {
        this.invoices[index] = new Invoice(item)
      })
    }

    if (this.estimates) {
      this.estimates.forEach((item, index) => {
        this.estimates[index] = new Estimate(item)
      })
    }
    this.price = new Price(this.price)
  }

  @computed
  get isPayed() {
    return PaymentStateEnum.payed === this.state.id
  }

  @computed
  get isRefused() {
    return PaymentStateEnum.refused === this.state.id
  }

  @computed
  get isCanceled() {
    return PaymentStateEnum.canceled === this.state.id
  }
}
