import { computed, makeObservable, observable } from 'mobx'
import { AbstractLabel } from '@core/models/abstractLabel'
import { BankDepositSlip } from '@common/models/bankDepositSlip/bankDepositSlip'
import { PaymentBankDepositStateEnum } from '@common/global/enums'

export class PaymentBankDeposit extends AbstractLabel {
  @observable
  slip: BankDepositSlip
  @observable
  state: AbstractLabel
  @observable
  bankReturnDate: Date

  constructor(data?: Partial<PaymentBankDeposit>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.slip) this.slip = new BankDepositSlip(this.slip)
    if (this.state) this.state = new AbstractLabel(this.state)
  }

  @computed
  get isDeposited() {
    return this.state?.id === PaymentBankDepositStateEnum.deposited
  }

  @computed
  get isPending() {
    return this.state?.id === PaymentBankDepositStateEnum.pending
  }

  @computed
  get isSuccess() {
    return this.state?.id === PaymentBankDepositStateEnum.success
  }

  @computed
  get isRejected() {
    return this.state?.id === PaymentBankDepositStateEnum.rejected
  }
}
