import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react'
import Maintenance from '@components/common/Maintenance'
import { CoreStore, coreStore } from '@core/store/coreStore'
import { useRouter } from 'next/router'
import { MeStore } from '@core/store/meStore'
import { User } from '@common/models/user/user'
import { observer } from 'mobx-react-lite'
import { referentialStore } from '@common/models/referential/referentialStore'
import { useStore } from '@utils/hooks'
import CardProvider from './cardContext'

type ContextType = {
  store: CoreStore
  isLoading: boolean
  isError: boolean
  isOnline: boolean
  reload: () => void
}

export const StoreContext = createContext<ContextType>({
  store: coreStore,
  isLoading: true,
  isError: false,
  isOnline: false,
  reload: null
})

const StoreProvider: FC<PropsWithChildren<any>> = observer(({ children }) => {
  const router = useRouter()
  const { store } = useContext(StoreContext)
  // Do not use store.meStore?.isLoading to avoid multiple renders
  const [isLoading, setIsLoading] = useState(() => true)
  const [isError, setIsError] = useState(() => false)

  const isOnline = useRef<boolean>(typeof window !== 'undefined' ? navigator.onLine : false)

  const interceptError = () => {
    //  if (error.response.status === 403) coreStore.routerStore.push('/')
  }

  const reload = () => {
    setIsLoading(true)
    setIsError(false)
    getStores()
  }

  const getStores = () => {
    Promise.all([store.meStore?.$get().catch(console.log), referentialStore.$get()])
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))
  }

  const goOnline = () => {
    isOnline.current = true
  }

  const goOffline = () => {
    isOnline.current = false
  }

  useEffect(() => {
    // internet detection
    window.addEventListener('online', goOnline)
    window.addEventListener('offline', goOffline)

    store.init('Super-ya', new MeStore(User), router, false, interceptError, {
      'X-Tenant-Id': process.env.TENANT_ID
    })
    getStores()

    return () => {
      window.removeEventListener('online', goOnline)
      window.removeEventListener('offline', goOffline)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <StoreContext.Provider value={{ store, isLoading, isError, isOnline: isOnline.current, reload }}>{children}</StoreContext.Provider>
})

export default StoreProvider

export const StoreWrapper: FC<PropsWithChildren<any>> = ({ children }) => {
  const { isLoading, isError, reload } = useStore()

  const getContent = () => {
    if (isError) {
      return <Maintenance reload={reload} />
    } else if (isLoading) {
      return null
    } else {
      return <CardProvider>{children}</CardProvider>
    }
  }

  return getContent()
}
