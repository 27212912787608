import { AbstractItem } from '@core/models/abstractItem'
import { PaymentPart } from '@common/models/paymentPart/paymentPart'
import { Estimate } from '@common/models/estimate/estimate'
import { Price } from '@common/models/price/price'
import { AbstractLabel } from '@core/models/abstractLabel'
import { Customer } from '@common/models/customer/customer'
import { Document } from '@core/models/document'
import { InvoiceDeadline } from '@common/models/invoiceDeadline/invoiceDeadline'
import { computed, makeObservable, observable } from 'mobx'
import { Contact } from '@common/models/contact/contact'
import { Credit } from '@common/models/credit/credit'
import { AbstractAttributeValues } from '@common/models/abstractAttributeValue/abstractAttributeValues'
import { InvoiceStateEnum } from '@common/global/enums'
import { Prices } from '@common/models/estimate/subscriptionPrice'
import { Payment } from '@common/models/payment/payment'
import { Subscription } from '@common/models/subscription/subscription'
import { User } from '@common/models/user/user'

export class Invoice extends AbstractAttributeValues {
  ref: string
  date: Date
  amount: number
  percentage: number
  paymentParts: PaymentPart[]
  estimate: Estimate
  subscription: Subscription
  @observable
  customer: Customer
  businessDealLabels: string
  state: AbstractLabel
  @observable
  leftToPay: Price
  @observable
  price: Price
  limitPaymentDate: Date
  isDelayed: boolean
  @observable
  purchaseOrder: Document
  documents: Document[]
  specialMention: string
  lastReminderDate: Date
  nbReminders: number
  @observable
  deadlines: InvoiceDeadline[] = []
  @observable
  recipients: InvoiceRecipient[]
  credits: Credit[]
  @observable
  withDirectDebit: boolean
  @observable
  directDebitPayments: Payment[]
  @observable
  commercial: User
  sendingDate: Date

  constructor(data?: Partial<Invoice>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
    if (this.estimate) {
      this.estimate = new Estimate(this.estimate)
      if (this.commercial == null) {
        this.commercial = this.estimate.commercial
      }
    }
    if (this.leftToPay) {
      this.leftToPay = new Price(this.leftToPay)
    }
    if (this.price) {
      this.price = new Price(this.price)
    }
    if (this.paymentParts) {
      this.paymentParts.forEach((item, index) => {
        this.paymentParts[index] = new PaymentPart(item)
      })
    }
    if (this.credits) {
      this.credits.forEach((item, index) => {
        this.credits[index] = new Credit(item)
      })
    }
    if (this.directDebitPayments) {
      this.directDebitPayments.forEach((item, index) => {
        this.directDebitPayments[index] = new Payment(item)
      })
    }
  }

  @computed
  get isNotPayed() {
    return this.state?.id !== InvoiceStateEnum.payed
  }

  @computed
  get pricesCreditPossible() {
    const data = new Prices({ totalPriceIncludingTaxes: new Price(this.price) })
    this.credits.forEach(credit => {
      data.totalPriceIncludingTaxes.amount -= credit.prices.totalPriceIncludingTaxes.amount
    })
    data.totalPriceIncludingTaxes.amount = Math.round(data.totalPriceIncludingTaxes.amount)
    return data
  }
}

export class InvoiceRecipient extends AbstractItem {
  contact: Contact

  constructor(data: Partial<InvoiceRecipient>) {
    super(data)
    Object.assign(this, data)
  }
}
