import { MouseEventHandler, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import useResponsive from './useResponsive'

export default function useResponsiveMenu(breakpoint: number): [boolean, MouseEventHandler<any>] {
  const [open, setOpen] = useState(() => false)
  const responsive = useResponsive(breakpoint)

  const toggle = useCallback((event?: SyntheticEvent) => {
    event?.stopPropagation()
    setOpen(prev => !prev)
  }, [])

  // Disable scrolling when responsive menu is open
  useEffect(() => {
    if (open && responsive) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [open, responsive])

  return [open, toggle]
}
