import { SkIconUilTransaction } from '@core/global/icons'
import { coreStore } from '@core/store/coreStore'
import { ApiStore } from '@core/api/apiStore'
import { Credit } from '@common/models/credit/credit'
import moment from 'moment'
import { Http } from '@core/api/http'
import { Message } from '@core/service/message'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Invoice } from '@common/models/invoice/invoice'
import { EstimateStore } from '@common/models/estimate/estimateStore'

export class CreditStore extends ApiStore<Credit> {
  @observable
  $validateLoading: boolean

  constructor() {
    super(coreStore.paramAppStore.apiUrl, '/credit', Credit, 'male', 'Avoirs', 'un avoir', 'cet avoir', SkIconUilTransaction)
    makeObservable(this)
  }

  async $deleteConfirm(item: Credit) {
    if (item.isValidated && item.remainingToUse && item.prices.totalPriceIncludingTaxes.amount != item.remainingToUse.amount) {
      Message.error('Vous ne pouvez pas supprimer un avoir utilisé ou partiellement utilisé')
      throw 'Skazy: action impossible'
    }
    await super.$deleteConfirm(item)
    new EstimateStore().invalidateQueries()
  }

  async $deleteByDate(item: Credit, date: Date): Promise<any> {
    return await this.$delete({ subUrlPath: `/${item.id}/date/${moment(date).format('YYYY-MM-DD')}` })
  }

  @action
  $validate = async (credit: Credit) => {
    try {
      this.$validateLoading = true
      await Http.$post(`${this.baseUrl}/${credit.id}/validate`)
      Message.success('L’avoir a été créé')
      this.invalidateQueries()
    } finally {
      runInAction(() => (this.$validateLoading = false))
    }
  }

  @action
  $createWithSelection(invoice: Invoice) {
    return Http.$post<Invoice>(`${this.baseUrl}/with-selection`, invoice).then(data => {
      this.invalidateQueries()
      return new Credit(data)
    })
  }

  async $downloadCredit(credit: Credit) {
    await this.$download(credit, `${credit.id}/pdf`, 'application/pdf', 'pdf', null, false, `Avoir n°${credit.ref}`)
  }

  async $clearCredit(credit) {
    return await Http.$put<Invoice>(`${this.baseUrl}/${credit.id}/clear`, credit.comment).then(() => this.invalidateQueries())
  }
}
