import { Typography } from '@mui/material'
import React, { FC } from 'react'
import { format } from 'date-fns'
import frLocale from 'date-fns/locale/fr'

type DateFormatterProps = {
  date: Date
  warning?: boolean
}

const DateFormatter: FC<DateFormatterProps> = ({ date, warning }) => {
  return <Typography className={warning ? 'font-bold capitalize text-super-ya-orange' : 'font-bold capitalize'}>{format(date, 'EEEE dd LLL', { locale: frLocale })}</Typography>
}

export default DateFormatter
