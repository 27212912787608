import React from 'react'
import { Typography } from '@mui/material'
import { Button, TitleField } from '@components/ui'
import { useStore } from '@utils/hooks'

const Maintenance = ({ reload }) => {
  const { isOnline } = useStore()

  const getContent = () => {
    if (isOnline) {
      return (
        <>
          <TitleField>Site en maintenance !</TitleField>
          <Typography>Nous nous excusons pour la gêne occasionnée. Patientez quelques instants puis cliquer sur relancer.</Typography>
          <div className="text-center">
            <Button variant="contained" color="primary" disableElevation onClick={reload}>
              <span className="py-2 font-bold normal-case">Relancer</span>
            </Button>
          </div>
        </>
      )
    } else {
      return (
        <>
          <TitleField>Pas de connexion à internet !</TitleField>
          <Typography>Veuillez vérifier votre connexion à internet puis cliquer sur relancer.</Typography>
          <div className="text-center">
            <Button variant="contained" color="primary" disableElevation onClick={reload}>
              <span className="py-2 font-bold normal-case">Relancer</span>
            </Button>
          </div>
        </>
      )
    }
  }

  return <div className="mx-auto mt-[50px] flex max-w-[95%] flex-col items-stretch space-y-[20px] lg:max-w-[730px]">{getContent()}</div>
}

export default Maintenance
