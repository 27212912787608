import React from 'react'
import { FlatButton, Link } from '@components/ui'
import { Typography } from '@mui/material'
import OrderIcon from '@assets/images/svg/ico-commander.svg'
import routes from '@const/routes'

const Order = () => {
  return (
    <Link href={routes.menus.uri}>
      <FlatButton variant="contained" color="primary" className="h-12.5 px-[13px]">
        <div className="flex">
          <OrderIcon />
          <div className="pl-2 capitalize extra-small:hidden">
            <Typography color="textSecondary" noWrap={true}>
              Commencer !
            </Typography>
          </div>
        </div>
      </FlatButton>
    </Link>
  )
}

export default Order
