import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { DefaultNavbar } from '../Navbar'
import BaseLayout from './BaseLayout'
import defaultBanner from '@assets/images/bandeau-comment-marche.jpg'
import defaultBannerWebP from '@assets/images/bandeau-comment-marche.webp'
import { Typography } from '@mui/material'
import { TitleField } from '@components/ui'

type ArticleLayoutProps = {
  banner?: ReactNode
  title?: string | ReactNode
  subtitle?: string | ReactNode
  additional?: ReactNode
}

const ArticleLayout: FC<PropsWithChildren<ArticleLayoutProps>> = ({ banner, title, subtitle, additional, children }) => {
  return (
    <BaseLayout navbar={<DefaultNavbar />}>
      <div className="flex h-full flex-col items-stretch">
        <div className="mx-auto mt-[30px]">
          {banner ?? (
            <picture>
              <source srcSet={defaultBannerWebP.src} type="image/webp" />
              <img src={defaultBanner.src} alt="Bannière" className="min-w-full rounded" width={1220} height={140} />
            </picture>
          )}
        </div>
        {title && (
          <div className="mx-auto mt-[30px] flex flex-col items-center justify-between space-y-[20px] px-[10px]">
            <TitleField className="text-center">{title}</TitleField>
            {subtitle && (
              <Typography color="textPrimary" className="text-center">
                {subtitle}
              </Typography>
            )}
          </div>
        )}
        {children}
        {additional}
      </div>
    </BaseLayout>
  )
}

export default ArticleLayout
