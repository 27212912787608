import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import data from '@const/super-ya.json'
import menu from '@const/menu.json'
import LogoIcon from '@assets/images/svg/logo-white.svg'
import CallIcon from '@assets/images/svg/ico-jappelle.svg'
import ProfileIcon from '@assets/images/svg/ico-compte.svg'
import MailIcon from '@assets/images/svg/ico-envoi-mail.svg'
import FollowIcon from '@assets/images/svg/ico-suivre.svg'
import FbIcon from '@assets/images/svg/ico-fbk-white.svg'
import ArrowIcon from '@assets/images/svg/arrow-md-right.svg'
import FooterContact from '@components/common/Footer/sections/FooterContact'
import { CTA } from '@components/ui'
import superyaColors from '@const/colors.json'

interface ComponentProps {}

const MainFooter: FC<ComponentProps> = () => {
  const { address, opening, contact, social } = data

  return (
    <div className="md:grid md:grid-cols-2">
      <div>
        <div className="mb-5 grid grid-cols-12 text-center md:text-left">
          <div className="col-span-10 col-start-2">
            <LogoIcon />
          </div>
        </div>
        <div className="mb-5">
          <div className="grid grid-cols-12">
            <div className="col-span-3 col-start-2 xl:col-span-2">
              <Typography color="textSecondary" variant="subtitle2" component="p">
                Adresse
              </Typography>
            </div>
            <div className="col-span-7">
              <Typography component="address" color="textSecondary">
                <Box fontStyle="normal" component="span">
                  {address.street}
                  <br />
                  {address.district}
                  <br />
                  {address.postcode} - {address.zipcode} {address.city}
                </Box>
              </Typography>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="grid grid-cols-12">
            <div className="col-span-3 col-start-2 xl:col-span-2">
              <Typography color="textSecondary" variant="subtitle2" component="p">
                Horaires
              </Typography>
            </div>
            <div className="col-span-7">
              <Typography color="textSecondary">
                {opening.label} <br />
                {opening.day_short} - {opening.hour}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10 place-content-start sm:grid sm:grid-cols-11 md:mt-5">
        <FooterContact icon={<CallIcon />} title="J’appelle" classes="mb-7.5 sm:mb-9 sm:col-start-2 col-span-5">
          <CTA color={superyaColors.white} hover={superyaColors.white} classes="h-full" label={`Tél: ${contact.countryCode} ${contact.phone}`} url={`tel:${contact.phoneUri}`} />
          <Typography color="textSecondary" className="h-full"></Typography>
        </FooterContact>
        <FooterContact icon={<MailIcon />} title="J’envoie un mail" classes="mb-7.5 sm:mb-9 col-span-5">
          <CTA
            color={superyaColors.white}
            hover={superyaColors.white}
            classes="h-full"
            label={
              <>
                <span>C'est par ici</span>
                <ArrowIcon className="pl-1" />
              </>
            }
            url={menu.contact.url}
          />
        </FooterContact>
        <FooterContact icon={<ProfileIcon />} title="Mon compte" classes="mb-7.5 sm:col-start-2 col-span-5">
          <CTA color={superyaColors.white} hover={superyaColors.white} classes="h-full" label={menu.account.label} url={menu.account.url} />
          <CTA color={superyaColors.white} hover={superyaColors.white} classes="h-full" label={menu.register.label} url={menu.register.url} />
        </FooterContact>
        <FooterContact icon={<FollowIcon />} title="Nous suivre" classes="col-span-5">
          <CTA
            color={superyaColors.white}
            hover={superyaColors.white}
            classes="h-full"
            label={<FbIcon />}
            url={social.facebook}
            ariaLabel="Voir notre page Facebook"
            target="_blank"
            rel="noopener"
          />
        </FooterContact>
      </div>
    </div>
  )
}
export default MainFooter
