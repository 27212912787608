import { AbstractLabel } from '@core/models/abstractLabel'
import { computed, makeObservable, observable } from 'mobx'
import { Price } from '@common/models/price/price'
import { Payment } from '@common/models/payment/payment'
import { Invoice } from '@common/models/invoice/invoice'
import { BankDepositSlipTypeEnum } from '@common/global/enums'

export class BankDepositSlip extends AbstractLabel {
  @observable
  date: Date
  @observable
  type: AbstractLabel
  @observable
  payments: Payment[]
  @observable
  invoices: Invoice[]
  @observable
  nbItems: number
  @observable
  nbRejects: number
  @observable
  totalAmount: Price
  @observable
  totalAmountCollected: Price

  constructor(data?: Partial<BankDepositSlip>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
  }

  @computed
  get isBankCheck() {
    return this.type.id === BankDepositSlipTypeEnum.bankCheck
  }

  @computed
  get isDirectDebit() {
    return this.type.id === BankDepositSlipTypeEnum.directDebit
  }
}
