import { action, observable } from 'mobx'
import { Attribute } from '@common/models/attribute/attribute'
import { Document } from '@core/models/document'
import { AbstractLabel } from '@core/models/abstractLabel'
import { AttributeValueFile } from '@common/models/abstractAttributeValue/attributeValueFile'
import { AttributeValue } from '@common/models/attributeValue/attributeValue'
import { VariantTracked } from '@common/models/variantTracked/variantTracked'

export class AbstractAttributeValues extends AbstractLabel {
  @observable
  attributeValues: AttributeValueFile[]

  constructor(data?: Partial<AbstractAttributeValues>) {
    super(data)
    Object.assign(this, data)
    if (this.attributeValues) {
      this.attributeValues.forEach((item, index) => {
        this.attributeValues[index] = new AttributeValueFile(item)
      })
    }
  }

  getAttributeValue(attribute: Attribute) {
    if (!this.attributeValues || !this.attributeValues.length) return null
    return this.attributeValues.find(x => x.attribute.id === attribute.id)
  }

  @action
  setValue(attribute: Attribute, value: string) {
    if (!this.attributeValues) this.attributeValues = []
    const index = this.attributeValues.findIndex(x => x.attribute.id === attribute.id)
    if (index === -1) {
      this.attributeValues.push(new AttributeValueFile({ attribute, value }))
    } else {
      this.attributeValues[index].value = value
    }
  }

  @action
  setAttributeValueFile(attribute: Attribute, attributeValueFile: AttributeValueFile) {
    if (!this.attributeValues) this.attributeValues = []
    const index = this.attributeValues.findIndex(x => x.attribute.id === attribute.id)
    if (index === -1) {
      this.attributeValues.push(attributeValueFile)
    } else {
      this.attributeValues[index] = attributeValueFile
    }
  }

  @action
  setAttributeValue(attribute: Attribute, attributeValue: AttributeValue) {
    if (!this.attributeValues) this.attributeValues = []
    const index = this.attributeValues.findIndex(x => x.attribute.id === attribute.id)
    if (index === -1) {
      this.attributeValues.push(new AttributeValueFile({ attribute, attributeValue }))
    } else {
      this.attributeValues[index].attributeValue = attributeValue
    }
  }

  @action
  removeAttributeValue(attribute: Attribute) {
    const index = this.attributeValues.findIndex(x => x.attribute.id === attribute.id)
    if (index !== -1) {
      this.attributeValues.splice(index, 1)
    }
  }

  @action
  setAttributeRequired(attribute: Attribute, isRequired: boolean) {
    if (!this.attributeValues) this.attributeValues = []
    const index = this.attributeValues.findIndex(x => x.attribute.id === attribute.id)
    if (index === -1) {
      this.attributeValues.push(new AttributeValueFile({ attribute, isRequired }))
    } else {
      this.attributeValues[index].isRequired = isRequired
    }
  }

  @action
  setAttributeDocument(attribute: Attribute, document: Document) {
    if (!this.attributeValues) this.attributeValues = []
    const index = this.attributeValues.findIndex(x => x.attribute.id === attribute.id)
    if (index === -1) {
      this.attributeValues.push(new AttributeValueFile({ attribute, file: document }))
    } else {
      this.attributeValues[index].file = document
    }
  }

  @action
  setAttributeVariantTracked(attribute: Attribute, variantTracked: VariantTracked) {
    if (!this.attributeValues) this.attributeValues = []
    const index = this.attributeValues.findIndex(x => x.attribute.id === attribute.id)
    if (index === -1) {
      this.attributeValues.push(new AttributeValueFile({ attribute, variantTracked }))
    } else {
      this.attributeValues[index].variantTracked = variantTracked
    }
  }
}
