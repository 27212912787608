import React, { FC } from 'react'
import NavbarRoot from './NavbarRoot'
import { Account, MenuDesktop, MenuMobile, Order } from './sections'
import { Button } from '@components/ui'
import MenuIcon from '@assets/images/svg/ico-menu.svg'
import { Close as CloseIcon } from '@mui/icons-material'
import { useResponsiveMenu } from '@utils/hooks'
import styles from '@const/styles.json'

const Navbar: FC = () => {
  const [open, toggle] = useResponsiveMenu(styles.breakpoint)

  return (
    <NavbarRoot>
      <nav className="mx-[10px] flex items-center justify-around py-[15px] xl:ml-[195px] xl:mr-[20px] xl:justify-evenly">
        <div className="small flex flex-grow items-center justify-start gap-8">
          <MenuDesktop />
          <MenuMobile open={open} />
        </div>
        <div className="flex flex-nowrap items-stretch justify-end space-x-[10px] xl:space-x-[30px]">
          <Account />
          <Order />
          <Button className="hidden h-12.5 w-12.5 min-w-0 responsive:flex" onClick={toggle} variant="outlined" color="primary">
            {open ? <CloseIcon /> : <MenuIcon />}
          </Button>
        </div>
      </nav>
    </NavbarRoot>
  )
}

export default Navbar
