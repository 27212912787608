import { AbstractItem } from '@core/models/abstractItem'
import { Payment } from '@common/models/payment/payment'
import { Price } from '@common/models/price/price'

export class PaymentPart extends AbstractItem {
  price: Price
  payment: Payment
  creditLabel: string

  constructor(data?: Partial<PaymentPart>) {
    super(data)
    Object.assign(this, data)
    if (this.payment) this.payment = new Payment(this.payment)
  }
}
