import React, { FC, ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import routes from '@const/routes'
import { CustomerAddress } from '@common/models/customerAddress/customerAddress'
import { Estimate } from '@common/models/estimate/estimate'
import { EstimateLine } from '@common/models/estimateLine/estimateLine'
import { EstimateStateEnum, PaymentStateEnum } from '@common/global/enums'
import { Package } from '@common/models/package/package'
import { PackageGroupTag } from '@common/models/packageGroup/packageGroupTag'
import { Planning } from '@common/models/planning/planning'
import { ShippingInfo } from '@common/models/shippingInfo/shippingInfo'
import { Variant } from '@common/models/variant/variant'
import { VariantStore } from '@common/models/variant/variantStore'
import { useStore } from '@utils/hooks'
import { Customer } from '@common/models/customer/customer'
import { EstimateStore } from '@common/models/estimate/estimateStore'
import { Message } from '@core/service/message'
import { EstimateLineStore } from '@common/models/estimateLine/estimateLineStore'

type Card = {
  estimate: Estimate
  estimateLines: Array<EstimateLine>
  extras: Array<Variant>
}

type AddExtraFunction = (extra: EstimateLine) => void

type AddExtraForAllFunction = (extra: Variant) => void

type AddShippingFunction = (customerAddress: CustomerAddress, date: number) => void

type AddShippingForAllFunction = (customerAddress: CustomerAddress) => void

type ValidateSyncFunction = () => Promise<any>

type AddToCardFunction = (planning: Planning, pack: Package, quantity: number) => void

type AddCustomToCardFunction = (lines: Array<EstimateLine>) => void

type CleanCardFunction = () => void

type GetLocaleEstimateFunction = () => Promise<Estimate>

type RemoveExtraFunction = (parentId: number, extraId: number) => void

type RemoveLineFunction = (lineId: number) => void

type SetQuantityFunction = (estimateLine: EstimateLine, quantity: number) => void

type TCardContext = {
  loading: boolean
  error: any
  card: Card
  total: number
  estimateId: number
  totalShipping: number
  totalIncludingTaxesWithShipping: number
  addExtra: AddExtraFunction
  addExtraForAll: AddExtraForAllFunction
  addShipping: AddShippingFunction
  addShippingForAll: AddShippingForAllFunction
  validateSync: ValidateSyncFunction
  addToCard: AddToCardFunction
  addCustomToCard: AddCustomToCardFunction
  cleanCard: CleanCardFunction
  getLocalEstimate: GetLocaleEstimateFunction
  reloadCard: () => void
  removeExtra: RemoveExtraFunction
  removeLine: RemoveLineFunction
  setQuantity: SetQuantityFunction
}

const CardContext = createContext<TCardContext>({} as TCardContext)

const storageKey = 'estimate'

type Props = {
  children: ReactNode
}

const CardProvider: FC<Props> = props => {
  const { children } = props

  const router = useRouter()

  const isMounted = useRef<boolean>(false)
  const { store } = useStore()

  const localEstimate = useRef<Estimate>()

  const [estimateLineStore] = useState<EstimateLineStore>(() => new EstimateLineStore())
  const [estimateStore] = useState<EstimateStore<Estimate>>(() => new EstimateStore())
  const [variantStore] = useState<VariantStore>(() => new VariantStore())
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(null)

  const [card, setCard] = useState<Card>({
    estimate: new Estimate(),
    estimateLines: [],
    extras: []
  })

  const { estimate } = card
  const {
    id: estimateId,
    totalPriceIncludingTaxes: { amount: total = 0 } = {},
    totalShippingPriceIncludingTaxes: { amount: totalShipping = 0 } = {},
    totalPriceIncludingTaxesWithShipping: { amount: totalIncludingTaxesWithShipping = 0 } = {}
  } = estimate

  const addExtra: AddExtraFunction = extra => {
    setLoading(true)
    estimateLineStore
      .$post(extra)
      .then(() => getCardData())
      .catch(e => {
        console.log('Error caught in addExtra : ', e)

        if (e.message.includes('status code 412')) {
          // 412 -> estimate was probably paid in gescom
          // remove estimate
          cleanCard()
        }

        setLoading(false)
      })
  }

  const addExtraForAll: AddExtraForAllFunction = extra => {
    setLoading(true)
    estimateStore
      .$addVariant({
        estimate: localEstimate.current,
        variant: extra
      })
      .then(() => getCardData())
      .catch(e => {
        console.log('Error caught in addExtraForAll : ', e)

        if (e.message.includes('status code 412')) {
          // 412 -> estimate was probably paid in gescom
          // remove estimate
          cleanCard()
        }

        setLoading(false)
      })
  }

  const validateSync: ValidateSyncFunction = async () => {
    return estimateStore
      .$validateSync(localEstimate.current)
      .then(result => {
        return result as object
      })
      .catch(e => {
        return false
      })
  }

  const addShipping: AddShippingFunction = (customerAddress, date) => {
    setLoading(true)
    estimateStore
      .$setShippingInfo(localEstimate.current, new ShippingInfo({ customerAddress, date: new Date(date) }))
      .then(() => {
        getCardData()
      })
      .catch(e => {
        console.log('Error caught in addShipping : ', e)

        if (e.message.includes('status code 412')) {
          // 412 -> estimate was probably paid in gescom
          // remove estimate
          cleanCard()
        }

        setLoading(false)
      })
  }

  const addShippingForAll: AddShippingForAllFunction = customerAddress => {
    setLoading(true)
    estimateStore
      .$setShippingInfo(localEstimate.current, new ShippingInfo({ customerAddress }))
      .then(() => {
        getCardData()
      })
      .catch(e => {
        console.log('Error caught in addShippingForAll : ', e)

        if (e.message.includes('status code 412')) {
          // 412 -> estimate was probably paid in gescom
          // remove estimate
          cleanCard()
        }

        setLoading(false)
      })
  }

  const addToCard: AddToCardFunction = async (planning, pack, quantity) => {
    setLoading(true)
    const { packageGroup: { label: menuLabel } = {} } = planning
    const { label } = pack

    // check if there is an estimate
    await getLocalEstimate()

    // add
    try {
      await estimateLineStore.$addLinePackage(localEstimate.current, planning, pack, quantity)
      Message.success(quantity + ' x ' + label + ' "' + menuLabel + '" ajouté au panier')
      getCardData()
    } catch (err) {
      console.error('Error caught in addToCard : ', err)

      if (err.message.includes('status code 412')) {
        // 412 -> estimate was probably paid in gescom
        // remove estimate
        cleanCard()
      }

      setLoading(false)
    }
  }

  const addCustomToCard: AddCustomToCardFunction = async lines => {
    setLoading(true)
    const { planning: { packageGroup: { label } = {} } = {} } = lines[0]
    try {
      await estimateStore.$addLines(localEstimate.current, lines)
      Message.success('Un menu "' + label + ' Sur Mesure" ajouté au panier')
      getCardData()
    } catch (err) {
      console.error('Error caught in addCustomToCard : ', err)

      if (err.message.includes('status code 412')) {
        // 412 -> estimate was probably paid in gescom
        // remove estimate
        cleanCard()
      }

      setLoading(false)
    }
  }

  const cleanCard: CleanCardFunction = () => {
    // remove localStorage item
    localStorage.removeItem(storageKey)

    // set default values
    if (isMounted.current) {
      setCard({ estimate: new Estimate(), estimateLines: [], extras: [] })
    }
  }

  const removeExtra: RemoveExtraFunction = (parentId, extraId) => {
    setLoading(true)
    estimateLineStore
      .$deleteById(extraId)
      .then(() => {
        getCardData()
      })
      .catch(e => {
        console.log('Error caught : ', e)

        if (e.message.includes('status code 412')) {
          // 412 -> estimate was probably paid in gescom
          // remove estimate
          cleanCard()
        }

        setLoading(false)
      })
  }

  const removeLine: RemoveLineFunction = lineId => {
    setLoading(true)
    // remove line
    estimateLineStore
      .$deleteById(lineId)
      .then(() => {
        getCardData()
      })
      .catch(e => {
        console.log('Error caught in removeLine : ', e)

        if (e.message.includes('status code 412')) {
          // 412 -> estimate was probably paid in gescom
          // remove estimate
          cleanCard()
        }
        reloadCard()
        setLoading(false)
      })
  }

  const setQuantity: SetQuantityFunction = (estimateLine, quantity) => {
    setLoading(true)
    // update quantity
    estimateLineStore
      .$setQuantity(estimateLine, quantity)
      .then(() => {
        getCardData()
      })
      .catch(e => {
        console.log('Error caught in setQuantity : ', e)

        if (e.message.includes('status code 412')) {
          // 412 -> estimate was probably paid in gescom
          // remove estimate
          cleanCard()
        }

        setLoading(false)
      })
  }

  const getExtras = async (estimateLines: Array<EstimateLine>) => {
    // get tags from estimate lines
    const tags = estimateLines.map(value => value.package?.packageGroup.packageGroupTags.map((y: PackageGroupTag) => y.tag.id)).flat()

    // get unique values
    const tagIds = [...new Set(tags)].filter(v => v !== undefined)

    // TODO handle if tag(s) already in memory and if not send request

    if (tagIds.length > 0) {
      const resultExtras =
        (await variantStore.$all({
          params: { customFilterInit: { tagIds } }
        })) || []

      return resultExtras
    } else {
      return []
    }
  }

  const getCardData = () => {
    if (!isMounted.current) {
      return
    }
    setLoading(true)

    estimateStore
      .$reload(localEstimate.current, { hideGlobalMessageError: true })
      .then(resultEstimate => {
        // check estimate if a draft
        if (
          (resultEstimate.state.id === EstimateStateEnum.validated && resultEstimate.paymentState.id === PaymentStateEnum.payed) ||
          resultEstimate.state.id === EstimateStateEnum.canceled
        ) {
          router.push({
            pathname: routes.confirmation.uri,
            query: { orderId: resultEstimate.id }
          })
          return
        } else if (resultEstimate.state.id !== EstimateStateEnum.draft) {
          cleanCard()

          if (isMounted.current) {
            setLoading(false)
          }
          return
        }

        estimateLineStore
          .$all({
            params: { filterInit: { estimate: { id: localEstimate.current?.id } } }
          })
          .then(async resultEstimatesLines => {
            // handle estimate lines with parent
            let index = resultEstimatesLines.length - 1
            while (index >= 0) {
              const line = resultEstimatesLines[index]
              if (line.parent) {
                // get parent
                const foundLine = resultEstimatesLines.find(l => l.id === line.parent.id)
                if (foundLine) {
                  // add in parent
                  if (!foundLine.children) foundLine.children = []
                  foundLine.children.push(line)
                }

                // delete line
                resultEstimatesLines.splice(index, 1)
              } else if (!line.planning || !line.planning.packageGroup) {
                // delete line if it's not a menu
                resultEstimatesLines.splice(index, 1)
              }
              index--
            }

            // get extras
            const extras = await getExtras(resultEstimatesLines)

            // handle extras on each estimate line
            resultEstimatesLines.forEach(line => {
              line.package?.packageGroup.packageGroupTags.forEach(packageGroupTag => {
                extras
                  .filter(x => x.article.articleTags?.some(y => y.tag.id === packageGroupTag.tag.id))
                  .forEach(extra => {
                    if (!line.children) line.children = []
                    const child = line.children.find(x => x.variant.id === extra.id)
                    if (!child) {
                      line.children.push(
                        new EstimateLine({
                          estimate: localEstimate.current,
                          quantity: line.quantity,
                          planning: line.planning,
                          day: line.day,
                          package: line.package,
                          label: extra.label ?? extra.article.label,
                          variant: extra,
                          parent: new EstimateLine({ id: line.id }),
                          isQuantityFromParent: packageGroupTag.isQuantityFromParent
                        })
                      )
                    }
                  })
              })

              // sort children
              line.children?.sort((a, b) => a.variant.id - b.variant.id)
            })

            // sort estimate lines
            resultEstimatesLines.sort((a, b) => {
              const { idx: indexA = 0, planning: { packageGroup: { idx: indexPA = 10000 } = {} } = {} } = a
              const cumulIndexA = indexPA + indexA
              const { idx: indexB = 0, planning: { packageGroup: { idx: indexPB = 10000 } = {} } = {} } = b
              const cumulIndexB = indexPB + indexB
              return cumulIndexA - cumulIndexB
            })

            if (isMounted.current) {
              setCard({
                estimate: resultEstimate as Estimate,
                estimateLines: resultEstimatesLines,
                extras
              })

              setLoading(false)
            }
          })
      })
      .catch(error => {
        console.log('Error caught in getCardData : ', error)

        if (error.message.includes('status code 403') || error.message.includes('status code 404') || error.message.includes('status code 412')) {
          // 403 -> estimate own to another person - probably on dev environment
          // 404 -> estimate was probably removed from gescom
          // 412 -> estimate was probably paid in gescom
          // remove estimate
          cleanCard()
        }

        if (isMounted.current) {
          setLoading(false)
          setError(error)
        }
      })
  }

  const getLocalEstimate: GetLocaleEstimateFunction = async () => {
    const local = localStorage.getItem(storageKey)

    if (local) {
      return JSON.parse(local) as Estimate
    } else {
      // get new
      const es = await estimateStore.$save(new Estimate({ customer: store.meStore?.data?.customer as Customer }))
      setLocalEstimate(es as Estimate)
      return es as Estimate
    }
  }

  const setLocalEstimate = (newEstimate: Estimate) => {
    localStorage.setItem(storageKey, JSON.stringify(newEstimate))
    localEstimate.current = newEstimate
  }

  const reloadCard = () => {
    const local = localStorage.getItem(storageKey)

    if (local) {
      localEstimate.current = JSON.parse(local)

      // get card
      getCardData()
    }
  }

  useEffect(() => {
    isMounted.current = true
    const local = localStorage.getItem(storageKey)

    if (local) {
      localEstimate.current = JSON.parse(local)

      // get card
      getCardData()
    } else {
      if (store.meStore?.data?.customer) {
        // try to get the latest estimate
        estimateStore
          .$lastDraft()
          .then(result => {
            if (result) {
              // save to localStorage
              setLocalEstimate(result)

              // get card
              getCardData()
            }
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    }
    return () => void (isMounted.current = false)
  }, [])

  return (
    <CardContext.Provider
      value={{
        loading,
        error,
        card,
        total,
        totalShipping,
        totalIncludingTaxesWithShipping,
        estimateId,
        addExtra,
        addExtraForAll,
        addShipping,
        addShippingForAll,
        addToCard,
        addCustomToCard,
        cleanCard,
        getLocalEstimate,
        reloadCard,
        removeExtra,
        removeLine,
        setQuantity,
        validateSync
      }}
    >
      {children}
    </CardContext.Provider>
  )
}

export default CardProvider

export const useCard = () => {
  return useContext<TCardContext>(CardContext)
}
