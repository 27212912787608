import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { StepType } from 'types/step'

const findActiveStep = (steps: Array<StepType>): number | undefined => {
  const activeStep = steps.findIndex(step => step.current)
  return activeStep !== -1 ? activeStep : undefined
}

export default function useSteps(progressSteps: Array<StepType>): [Array<StepType>, number | undefined] {
  const router = useRouter()
  const [steps, setSteps] = useState<Array<StepType>>(() => progressSteps)
  const [activeStep, setActiveStep] = useState<number | undefined>(() => findActiveStep(steps))

  useEffect(() => {
    setSteps(previous =>
      previous.map(step => {
        let current = false
        if (Array.isArray(step.paths)) {
          current = step.paths.some(path => router.asPath === path)
        } else if (typeof step.paths === 'string') {
          current = router.asPath === step.paths
        }
        return { ...step, current: current }
      })
    )
  }, [router.asPath])

  useEffect(() => {
    setActiveStep(findActiveStep(steps))
  }, [steps])

  return [steps, activeStep]
}
