import { AbstractLabel } from '@core/models/abstractLabel'
import { VariantTracked } from '@common/models/variantTracked/variantTracked'
import { action, makeObservable, observable } from 'mobx'
import { Variant } from '@common/models/variant/variant'
import { Price } from '@common/models/price/price'
import { Package } from '@common/models/package/package'
import { Planning } from '@common/models/planning/planning'
import { ShippingInfo } from '@common/models/shippingInfo/shippingInfo'
import { AbstractEstimate } from '@common/models/estimate/abstractEstimate'
import { StockTracking } from '@common/models/stockTracking/stockTracking'
import { Discount } from '@common/models/discount/discount'
import { AbstractUi } from '@core/models/abstractItem'
import { EstimateLineTax } from '@common/models/estimateLine/estimateLineTax'
import { Frequency } from '@common/models/frequency/frequency'
import { referentialStore } from '@common/models/referential/referentialStore'
import { DiscountCodeEnum, TransactionTypeEnum } from '@common/global/enums'
import { ArticleUnit } from '@common/models/articleUnit/articleUnit'
import { AbstractLabelObservable } from '@core/models/abstractLabelObservable'
import { InvoiceDeadline } from '@common/models/invoiceDeadline/invoiceDeadline'
import { Equipment } from '@common/models/equipment/equipment'
import { StockTrackingStat } from '@common/models/stockTrackingStats/stockTrackingStat'
import { Tools } from '@core/utils/tools'
import { CreditLine } from '@common/models/creditLine/creditLine'
import { EstimateLineSaleTag } from '@common/models/estimateLine/estimateLineSaleTag'

export class EstimateLine extends AbstractLabel {
  @observable
  idx: number
  @observable
  package: Package
  @observable
  planning: Planning
  @observable
  cost: Price
  @observable
  price: Price
  @observable
  taxes: EstimateLineTax[]
  @observable
  quantity: number
  @observable
  quantityCancelled: number
  @observable
  paymentType: AbstractLabelObservable
  @observable
  variant: Variant
  @observable
  parent: EstimateLine
  @observable
  children: EstimateLine[]
  @observable
  variantTracked: VariantTracked
  @observable
  shippingInfo: ShippingInfo
  @observable
  costTaxesValue: Price
  lastStockTracking: StockTracking
  warehousesInfo: StockTrackingStat[]
  @observable
  totalPriceExcludingTaxesWithoutDiscount: Price
  @observable
  totalPriceExcludingTaxesWithDiscount: Price
  @observable
  totalDiscountRate: number
  @observable
  totalPriceIncludingTaxes: Price
  @observable
  totalTaxes: Price
  @observable
  unitPriceIncludingTaxes: Price
  @observable
  estimate: AbstractEstimate
  invoiceDeadline: InvoiceDeadline
  isQuantityFromParent: boolean
  day: number
  state: AbstractLabel
  shippingDate: Date
  degressiveGroup: DegressiveGroup
  @observable
  discount: Discount
  declare ui: Ui
  @observable
  sectionId: number
  @observable
  description: string
  isSubscription: boolean
  @observable
  invoiceFrequency: Frequency
  @observable
  unit: ArticleUnit
  @observable
  equipment: Equipment
  @observable
  startDate: Date
  @observable
  endDate: Date
  original: EstimateLine
  transactionType: AbstractLabel
  @observable
  saleTags: EstimateLineSaleTag[]

  constructor(data?: Partial<EstimateLine>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (data?.invoiceFrequency) this.isSubscription = true
    if (Tools.isNullOrUndefined(this.quantity)) this.quantity = 1
    if (this.paymentType) this.paymentType = new AbstractLabelObservable(this.paymentType)
    if (this.variant) this.variant = new Variant(this.variant)
    if (this.variantTracked) this.variantTracked = new VariantTracked(this.variantTracked)
    if (this.package) this.package = new Package(this.package)
    if (this.planning) this.planning = new Planning(this.planning)
    if (this.cost) this.cost = new Price(this.cost)
    if (this.price) this.price = new Price(this.price)
    if (this.taxes?.length) this.taxes.forEach((item, index) => (this.taxes[index] = new EstimateLineTax(item)))
    if (this.shippingInfo) this.shippingInfo = new ShippingInfo(this.shippingInfo)
    if (this.degressiveGroup) this.degressiveGroup = new DegressiveGroup(this.degressiveGroup)
    if (this.children?.length) this.children.forEach((item, index) => (this.children[index] = new EstimateLine(item)))
    if (this.invoiceFrequency) this.invoiceFrequency = new Frequency(this.invoiceFrequency)
    if (this.unit) this.unit = new ArticleUnit(this.unit)
    if (this.label) this.label = this.label.replace(/\\n/g, '\n')
    if (this.original) this.original = new EstimateLine(this.original)
    if (!this.transactionType) this.transactionType = new AbstractLabel({ id: TransactionTypeEnum.sale })
    this.ui = new Ui()
  }

  @action
  setDiscount = (percentage: number, discountType: DiscountCodeEnum) => {
    if (!percentage) {
      this.discount = null
      return
    }
    if (!this.discount) this.discount = new Discount()
    this.discount.type = referentialStore.data.discountTypes.find(x => x.code === discountType)
    this.discount.percentage = percentage
  }
}

class Ui extends AbstractUi {
  canEditQuantity = true
  @observable
  totalPriceIsChanging = false
  remainingQuantity: number
  creditLine: CreditLine
  constructor() {
    super()
    makeObservable(this)
  }
}

class DegressiveGroup {
  id: number
  @observable
  discount: Discount
  constructor(data?: Partial<DegressiveGroup>) {
    makeObservable(this)
    Object.assign(this, data)
    if (this.discount) this.discount = new Discount(this.discount)
  }
}
