import { withStyles } from '@mui/styles'
import { Button } from '@mui/material'
import { darken } from '@mui/material/styles'

export default withStyles(theme => ({
  root: {
    borderRadius: 2,
    boxShadow: 'none',
    height: '50px'
  },
  contained: {
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.1)
    }
  }
}))(Button)
