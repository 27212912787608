import React, { FC } from 'react'
import { Box, ButtonBase, InputBase } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import { withProps } from '@utils/hoc'
import { withStyles } from '@mui/styles'

type CounterInputProps = {
  value: number
  onChange: any
  step?: number
  disabled?: boolean
  height?: number
  minValue?: number
}

const Input = withStyles({
  root: {
    height: 'unset',
    backgroundColor: 'unset'
  },
  input: {
    textAlign: 'center'
  }
})(InputBase)

const CounterInput: FC<CounterInputProps> = ({ value, onChange, step = 1, disabled, height, minValue = 0 }) => {
  const Button = withProps(ButtonBase, { disabled })
  const handleChange = (event: any) => {
    event.preventDefault()
    const val = event?.target?.value
    if (/^\d+$/.test(val)) {
      onChange(parseInt(val))
    }
  }

  return (
    <Box border={1} borderRadius="4px" borderColor="rgb(229, 231, 235)" height={height} className="flex justify-between bg-white">
      <Button className="p-2 sm:p-0" onClick={() => onChange(Math.max(minValue, value - step))}>
        <Remove color="primary" className="text-[24px] sm:text-[16px]" />
      </Button>
      <Input
        value={value}
        disabled={disabled}
        inputProps={{ 'aria-label': 'naked' }}
        className="mx-auto h-auto max-w-[50px]"
        // @ts-ignore
        onChange={handleChange}
        onPaste={() => false}
      />
      <Button className="p-2 sm:p-0" onClick={() => onChange(value + step)}>
        <Add color="primary" className="text-[24px] sm:text-[16px]" />
      </Button>
    </Box>
  )
}

export default CounterInput
