import { BankAccount } from '@core/models/bankAccount'
import { Province } from '@core/models/province'
import { ArticleUnit } from '@common/models/articleUnit/articleUnit'
import { Warehouse } from '@common/models/warehouse/warehouse'
import { Brand } from '@common/models/brand/brand'
import { User } from '@common/models/user/user'
import { SuscriptionInvoicingPeriodEnum, TransactionTypeEnum, WarehouseTypeEnum } from '@common/global/enums'
import { PermissionType, Role } from '@core/models/role'
import { AbstractLabel } from '@core/models/abstractLabel'
import { City } from '@core/models/city'
import { Tag } from '@common/models/tag/tag'
import { TenantProperties } from '@common/models/tenant/tenant'
import { Tax } from '@common/models/tax/tax'
import moment from 'moment'
import { TaxMention } from '@common/models/taxMention/taxMention'
import { ArticleUnitGroup } from '@common/models/articleUnitGroup/articleUnitGroup'
import { FinancialYear } from '@common/models/accounting/financialYear'
import { Bank } from '@core/models/bank'
import { Company } from '@core/models/company'
import { GeneralAccountingAccount } from '@common/models/accounting/generalAccountingAccount'
import { FileFormat } from '@common/models/file/fileFormat'
import { Currency } from '@common/models/currency/currency'

export class Referential {
  authenticationTypes: AbstractLabel[]
  bundles: AbstractLabel[]
  estimateFieldNaming: AbstractLabel[]
  locationReferentials: AbstractLabel[]
  provinces: Province[]
  roles: Role[]
  legalStatus: AbstractLabel[]
  functions: AbstractLabel[]
  titles: AbstractLabel[]
  tags: Tag[]
  taxes: Tax[]
  taxMentions: TaxMention[]
  variantTrackedStates: AbstractLabel[]
  brands: Brand[]
  bankAccounts: BankAccount[]
  currencies: Currency[]
  articleUnits: ArticleUnit[]
  articleUnitGroups: ArticleUnitGroup[]
  estimateCategories: AbstractLabel[]
  warehousesTypes: AbstractLabel[]
  warehousesActionsTypes: AbstractLabel[]
  warehouses: Warehouse[]
  users: User[]
  personTypes: AbstractLabel[]
  paymentTypes: AbstractLabel[]
  estimateStates: AbstractLabel[]
  interventionStates: AbstractLabel[]
  paymentStates: AbstractLabel[]
  invoiceStates: AbstractLabel[]
  isCompleteChoices: AbstractLabel<boolean>[]
  isBooleanChoices: AbstractLabel<boolean>[]
  permissionTypes: PermissionType[]
  warehousesNormal: Warehouse[]
  warehousesTransit: Warehouse[]
  warehousesSocalog: Warehouse[]
  cities: City[]
  tenantProperties: TenantProperties
  frequencies: AbstractLabel[]
  discountTypes: AbstractLabel[]
  daysOfWeek: AbstractLabel[] = []
  paymentProviderModes: AbstractLabel[] = []
  paymentProviders: AbstractLabel[] = []
  batchStatus: AbstractLabel[]
  periods: AbstractLabel<string>[]
  accountingAccountTypes: AbstractLabel[]
  accountingJournalTypes: AbstractLabel[]
  accountingExportTypes: AbstractLabel[]
  accountingSoftwares: AbstractLabel[]
  bankDepositSlipTypes: AbstractLabel[]
  fileFormats: FileFormat[]
  financialYears: FinancialYear[]
  banks: Bank[]
  companies: Company[]
  generalAccountingAccounts: GeneralAccountingAccount[]
  transactionTypes: AbstractLabel[]
  taxCodes: AbstractLabel<string>[]
  saleTags: AbstractLabel<string>[]
  subscriptionInvoicingPeriods: AbstractLabel<SuscriptionInvoicingPeriodEnum>[]

  constructor(data?: Partial<Referential>) {
    Object.assign(this, data)
    if (this.tenantProperties) {
      this.tenantProperties = new TenantProperties(this.tenantProperties)
    }

    if (this.variantTrackedStates) {
      this.variantTrackedStates.forEach((item, index) => {
        this.variantTrackedStates[index] = new AbstractLabel(item)
      })
    }
    if (this.provinces) {
      this.provinces.forEach((item, index) => {
        this.provinces[index] = new Province(item)
      })
      this.cities = []
      this.provinces.forEach(p => this.cities.push(...p.cities))
      this.cities.sort((x, y) => x.label.localeCompare(y.label))
    }
    if (this.roles) {
      this.roles.forEach((item, index) => {
        this.roles[index] = new Role(item)
      })
    }
    if (this.legalStatus) {
      this.legalStatus.forEach((item, index) => {
        this.legalStatus[index] = new AbstractLabel(item)
      })
    }
    if (this.titles) {
      this.titles.forEach((item, index) => {
        this.titles[index] = new AbstractLabel(item)
      })
    }
    if (this.tags) {
      this.tags.forEach((item, index) => {
        this.tags[index] = new Tag(item)
      })
      this.tags = this.tags.sort((a, b) => a.label.localeCompare(b.label))
    }
    if (this.bankAccounts) {
      this.bankAccounts.forEach((item, index) => {
        this.bankAccounts[index] = new BankAccount(item)
      })
    }
    if (this.legalStatus) {
      this.legalStatus.forEach((item, index) => {
        this.legalStatus[index] = new AbstractLabel(item)
      })
    }
    if (this.functions) {
      this.functions.forEach((item, index) => {
        this.functions[index] = new AbstractLabel(item)
      })
    }
    if (this.currencies) {
      this.currencies.forEach((item, index) => {
        this.currencies[index] = new Currency(item)
      })
    }
    if (this.articleUnits) {
      this.articleUnits.forEach((item, index) => {
        this.articleUnits[index] = new ArticleUnit(item)
      })
    }
    if (this.articleUnitGroups) {
      this.articleUnitGroups.forEach((item, index) => {
        this.articleUnitGroups[index] = new ArticleUnitGroup(item)
      })
    }
    if (this.estimateCategories) {
      this.estimateCategories.forEach((item, index) => {
        this.estimateCategories[index] = new AbstractLabel(item)
      })
    }
    if (this.warehousesTypes) {
      this.warehousesTypes.forEach((item, index) => {
        this.warehousesTypes[index] = new AbstractLabel(item)
      })
    }
    if (this.warehousesActionsTypes) {
      this.warehousesActionsTypes.forEach((item, index) => {
        this.warehousesActionsTypes[index] = new AbstractLabel(item)
      })
    }
    if (this.warehouses) {
      this.warehouses.forEach((item, index) => {
        this.warehouses[index] = new Warehouse(item)
      })
      this.warehousesNormal = this.warehouses.filter(x => x.type.id !== WarehouseTypeEnum.transit && x.type.id !== WarehouseTypeEnum.socalog)
      this.warehousesTransit = this.warehouses.filter(x => x.type.id === WarehouseTypeEnum.transit)
      this.warehousesSocalog = this.warehouses.filter(x => x.type.id === WarehouseTypeEnum.socalog)
    }
    if (this.brands) {
      this.brands.forEach((item, index) => {
        this.brands[index] = new Brand(item)
      })
    }
    if (this.personTypes) {
      this.personTypes.forEach((item, index) => {
        this.personTypes[index] = new AbstractLabel(item)
      })
    }
    if (this.permissionTypes) {
      this.permissionTypes.forEach((item, index) => {
        this.permissionTypes[index] = new PermissionType(item)
      })
    }
    if (this.authenticationTypes) {
      this.authenticationTypes.forEach((item, index) => {
        this.authenticationTypes[index] = new AbstractLabel(item)
      })
    }
    if (this.taxes) {
      this.taxes.forEach((item, index) => {
        this.taxes[index] = new Tax(item)
        this.taxes[index].label = `${item.code} ${item.rate}% (${item.label})`
      })
      this.taxCodes = this.taxes
        .map(tax => new AbstractLabel({ id: tax.code, label: tax.code }))
        .filter((taxCode, i, arr) => {
          return arr.indexOf(arr.find(t => t.id === taxCode.id)) === i
        })
    }
    if (this.frequencies) {
      this.frequencies = this.frequencies.sort((x, y) => x.id - y.id)
    }
    if (this.paymentProviderModes) {
      this.paymentProviderModes.forEach((item, index) => {
        this.paymentProviderModes[index] = new AbstractLabel(item)
      })
    }
    if (this.paymentProviders) {
      this.paymentProviders.forEach((item, index) => {
        this.paymentProviders[index] = new AbstractLabel(item)
      })
    }
    if (this.transactionTypes) {
      this.transactionTypes.forEach((item, index) => {
        this.transactionTypes[index] = new AbstractLabel(item)
      })
      if (!this.tenantProperties.articleProperties.withSubscription) {
        this.transactionTypes = this.transactionTypes.filter(x => x.id === TransactionTypeEnum.sale)
      }
    }
    this.isBooleanChoices = [new AbstractLabel({ id: true, label: 'Oui' }), new AbstractLabel({ id: false, label: 'Non' })]
    this.isCompleteChoices = [new AbstractLabel({ id: true, label: 'Complet' }), new AbstractLabel({ id: false, label: 'Incomplet' })]
    for (let i = 0; i < 7; i++) {
      const d = moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
      this.daysOfWeek.push(new AbstractLabel({ id: d.day(), label: d.format('dddd') }))
    }
    this.periods = [
      new AbstractLabel({ id: 'P30D', label: 'Dans 30 jours' }),
      new AbstractLabel({ id: 'P60D', label: 'Dans 60 jours' }),
      new AbstractLabel({ id: 'P90D', label: 'Dans 90 jours' }),
      new AbstractLabel({ id: 'P1M', label: 'Dans 1 mois' }),
      new AbstractLabel({ id: 'P2M', label: 'Dans 2 mois' }),
      new AbstractLabel({ id: 'P3M', label: 'Dans 3 mois' })
    ]
    if (this.accountingAccountTypes) {
      this.accountingAccountTypes.forEach((item, index) => {
        this.accountingAccountTypes[index] = new AbstractLabel(item)
      })
    }
    if (this.accountingJournalTypes) {
      this.accountingJournalTypes.forEach((item, index) => {
        this.accountingJournalTypes[index] = new AbstractLabel(item)
      })
    }
    if (this.bankDepositSlipTypes) {
      this.bankDepositSlipTypes.forEach((item, index) => {
        this.bankDepositSlipTypes[index] = new AbstractLabel(item)
      })
    }
    if (this.financialYears) {
      this.financialYears.forEach((item, index) => {
        this.financialYears[index] = new FinancialYear(item)
      })
    }
  }
}
