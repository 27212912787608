import { FC, PropsWithChildren, ReactNode } from 'react'
import { Typography } from '@mui/material'

type ComponentProps = {
  icon: ReactNode
  title: string
  classes?: string
}

const FooterContact: FC<PropsWithChildren<ComponentProps>> = ({ icon, title, children, classes }) => {
  return (
    <div className={`grid grid-cols-12 place-content-start gap-2 ${classes}`}>
      <div className="col-span-1 col-start-2 sm:col-span-full">{icon}</div>
      <div className="col-span-9 sm:col-span-full">
        <div className="flex items-stretch justify-between sm:flex-col">
          <div className="sm:mb-3">
            <Typography variant="subtitle2" color="textSecondary" component="p">
              {title}
            </Typography>
          </div>
          <div className="text-right sm:text-left">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default FooterContact
