import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { DefaultNavbar } from '../Navbar'
import BaseLayout from './BaseLayout'

type AccountLayoutProps = {
  title?: ReactNode
  additional?: ReactNode
}

const AccountLayout: FC<PropsWithChildren<AccountLayoutProps>> = ({ title, additional, children }) => {
  return (
    <BaseLayout navbar={<DefaultNavbar />}>
      <div className="flex h-full flex-col items-stretch">
        {title && <div className="container relative z-50 mx-auto my-[30px]">{title}</div>}
        {children}
        {additional && <div className="container mx-auto mb-[100px] mt-[50px] flex flex-col justify-between space-y-[40px]">{additional}</div>}
      </div>
    </BaseLayout>
  )
}

export default AccountLayout
