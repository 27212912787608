import { useEffect, useState } from 'react'

type State = {
  responsive: boolean
}

export default function useResponsive(breakpoint: number): State {
  const [isResponsive, setIsResponsive] = useState(() => typeof window !== 'undefined' && window?.innerWidth < breakpoint)

  useEffect(() => {
    const handleResize = () => setIsResponsive(window.innerWidth < breakpoint)
    window.addEventListener('resize', handleResize)
    return () => {
      // Cleanup resources
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoint])

  return { responsive: isResponsive }
}
