import { makeObservable, observable } from 'mobx'
import { AbstractItem, AbstractUi } from '@core/models/abstractItem'
import { PackageGroup } from '@common/models/packageGroup/packageGroup'
import { Variant } from '@common/models/variant/variant'

export class Planning extends AbstractItem {
  @observable
  packageGroup: PackageGroup
  @observable
  variants: Variant[]
  @observable
  date: Date
  @observable
  isPublished: boolean
  declare ui: Ui

  constructor(data?: Partial<Planning>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.packageGroup) {
      this.packageGroup = new PackageGroup(this.packageGroup)
    }
    if (this.variants) {
      this.variants.forEach((item, index) => {
        this.variants[index] = new Variant(item)
      })
    }
    if (this.date) this.date = new Date(this.date)
    this.ui = new Ui()
  }

  getTotalPriceForVariants = (globalDiscountPercentage = 0) => {
    let total = 0
    this.variants?.forEach(variant => {
      if (variant.ui.quantity) {
        total += variant.ui.getTotalPriceIncludingTaxes(globalDiscountPercentage)
      }
    })
    return total
  }
}

class Ui extends AbstractUi {
  isBreak = false
}
