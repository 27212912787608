import { AbstractItem } from '@core/models/abstractItem'
import { makeObservable, observable } from 'mobx'
import { Attribute } from '@common/models/attribute/attribute'
import { Document } from '@core/models/document'
import { FieldTypeEnum } from '@common/global/enums'
import moment from 'moment'
import { AttributeValue } from '@common/models/attributeValue/attributeValue'
import { SkIconUilFileCheck } from '@core/global/icons'
import { FileTools } from '@core/utils/fileTools'
import { coreStore } from '@core/store/coreStore'
import { VariantTracked } from '@common/models/variantTracked/variantTracked'
import { SkArticleImageListItem } from '@common/models/article/articleStore'
import React from 'react'

export class AttributeValueFile extends AbstractItem {
  @observable
  attribute: Attribute
  @observable
  attributeValue: AttributeValue
  @observable
  value: string
  @observable
  file: Document
  @observable
  isRequired: boolean
  @observable
  idx: number
  @observable
  variantTracked: VariantTracked

  constructor(data?: Partial<AttributeValueFile>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.attribute) this.attribute = new Attribute(this.attribute)
    if (this.attributeValue) this.attributeValue = new AttributeValue(this.attributeValue)
    if (this.file) this.file = new Document(this.file)
    if (this.variantTracked) this.variantTracked = new VariantTracked(this.variantTracked)
  }

  valueToShow(noLink?: boolean) {
    if (this.attribute.fieldType.id === FieldTypeEnum.boolean) {
      if (this.value === 'true') return 'Oui'
      return 'Non'
    } else if (
      this.attribute.fieldType.id === FieldTypeEnum.textAndCheckbox ||
      this.attribute.fieldType.id === FieldTypeEnum.number ||
      this.attribute.fieldType.id === FieldTypeEnum.text ||
      this.attribute.fieldType.id === FieldTypeEnum.textarea
    ) {
      return this.value ? this.value : '-'
    } else if (this.attribute.fieldType.id === FieldTypeEnum.date) {
      return this.value ? moment(this.value).format('LL') : '-'
    } else if (this.attribute.fieldType.id === FieldTypeEnum.file) {
      return this.file ? <SkFile value={this.file} /> : '-'
    } else if (this.attribute.fieldType.id === FieldTypeEnum.list) {
      return this.attributeValue ? this.attributeValue.labelT : '-'
    } else if (this.attribute.fieldType.id === FieldTypeEnum.variantTracked) {
      return this.variantTracked ? <SkArticleImageListItem variantTracked={this.variantTracked} noLink={noLink} /> : '-'
    }
    return '-'
  }
}

const SkFile = ({ value }: { value: Document }) => {
  const downloadDocument = () => {
    FileTools.downloadByUrl(`${coreStore.paramAppStore.apiUrl}/file/download/${value.filename}`, value.originalFileName)
  }

  return (
    <div className="flex items-center justify-between gap-x-3">
      <div className="flex items-center gap-x-2">
        <span className="text-gray-400">
          <SkIconUilFileCheck width={20} />
        </span>
        <a onClick={downloadDocument} id="downloadDocument" className="text-color">
          {value.originalFileName}
        </a>
      </div>
    </div>
  )
}
