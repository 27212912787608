import { useRouter } from 'next/router'
import routes from '@const/routes'

const usePageTitle = () => {
  const path = useRouter().asPath.split('?')[0]

  const key = Object.keys(routes)?.find(key => routes[key].uri === path)

  const { description = '', title = '' } = key ? routes[key] : {}

  return { description, title }
}

export default usePageTitle
