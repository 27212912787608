import { Estimate } from '@common/models/estimate/estimate'
import { AbstractItem } from '@core/models/abstractItem'
import { Invoice } from '@common/models/invoice/invoice'
import { Price } from '@common/models/price/price'
import { Frequency } from '@common/models/frequency/frequency'
import { action, makeObservable, observable } from 'mobx'
import { AbstractLabelObservable } from '@core/models/abstractLabelObservable'
import { InvoicePlanTypeEnum, SuscriptionInvoicingPeriodEnum } from '@common/global/enums'
import { Tools } from '@core/utils/tools'
import { AbstractEstimate } from '@common/models/estimate/abstractEstimate'
import { AbstractLabel } from '@core/models/abstractLabel'

export class InvoicePlan extends AbstractItem {
  @observable
  type: AbstractLabelObservable
  estimate: AbstractEstimate
  @observable
  invoices: Invoice[]
  startDate: Date
  endDate: Date
  @observable
  remainingAmountToPay: Price
  @observable
  invoiceFrequency: Frequency
  @observable
  invoicingPeriod: AbstractLabel<SuscriptionInvoicingPeriodEnum>
  @observable
  period: string
  @observable
  isTacitRenewal: boolean
  subscriptionDuration: string
  @observable
  invoicingEnabled: boolean

  constructor(data?: Partial<InvoicePlan>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
    if (this.type) this.type = new AbstractLabelObservable(this.type)
    if (this.estimate) this.estimate = new Estimate(this.estimate)
    if (this.invoices) {
      this.invoices.forEach((item, index) => {
        this.invoices[index] = new Invoice(item)
      })
    }
    if (Tools.isNullOrUndefined(this.isTacitRenewal)) this.isTacitRenewal = true
    if (this.invoiceFrequency) this.invoiceFrequency = new Frequency(this.invoiceFrequency)
  }

  @action
  setType = (id: InvoicePlanTypeEnum) => {
    if (!this.type) this.type = new AbstractLabelObservable()
    this.type.id = id
  }
}
