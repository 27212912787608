import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable } from 'mobx'

export class TaxMention extends AbstractLabel {
  description: string

  constructor(data?: Partial<TaxMention>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}
