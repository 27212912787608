import { AbstractLabel } from '@core/models/abstractLabel'
import { withStyles } from '@mui/styles'

const statusBackgroundColors = {
  Brouillon: 'rgba(216, 216, 216, 0.15)',
  'En cours': 'rgba(13,199,119, 0.15)',
  TO_PAY: 'rgb(246, 224, 163)',
  PAYED: 'rgba(0, 158, 224, 0.15)',
  Validé: 'rgba(0, 158, 224, 0.15)',
  Facturé: 'rgba(0, 158, 224, 0.15)'
}

const statusTextColors = {
  Brouillon: '#344547',
  'En cours': '#0DC777',
  TO_PAY: 'rgb(176, 80, 80)',
  PAYED: '#009EE0',
  Validé: '#009EE0',
  Facturé: '#009EE0'
}

const StatusHOC = (status: AbstractLabel, Component: any) =>
  withStyles({
    root: {
      backgroundColor: status.code ? statusBackgroundColors[status.code] : statusBackgroundColors['Brouillon'],
      color: status.code ? statusTextColors[status.code] : statusTextColors['Brouillon']
    }
  })(Component)

export default StatusHOC
