import { AbstractItem } from '@core/models/abstractItem'
import { observable } from 'mobx'

export class AbstractTaxed extends AbstractItem {
  @observable
  taxLabel?: string
  @observable
  taxRate?: number
  @observable
  taxCode?: string

  constructor(data: any = null) {
    super(data)
    Object.assign(this, data)
  }
}
