import { AbstractItem } from '@core/models/abstractItem'
import { CustomerAddress } from '@common/models/customerAddress/customerAddress'
import { Price } from '@common/models/price/price'
import { AbstractLabel } from '@core/models/abstractLabel'
import { ShippingInfoTax } from '@common/models/shippingInfo/shippingInfoTax'

export class ShippingInfo extends AbstractItem {
  date: Date
  day: number
  quantity: number
  comment: string
  customerAddress: CustomerAddress
  price: Price
  unitPriceIncludingTaxes: Price
  taxes: ShippingInfoTax[]
  totalPriceExcludingTaxesWithoutDiscount: Price
  totalPriceExcludingTaxesWithDiscount: Price
  totalDiscountRate: number
  totalPriceIncludingTaxes: Price
  totalTaxes: Price
  state: AbstractLabel

  constructor(data?: Partial<ShippingInfo>) {
    super(data)
    Object.assign(this, data)
    if (this.customerAddress) this.customerAddress = new CustomerAddress(this.customerAddress)
  }
}
