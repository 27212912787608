import React, { FC } from 'react'

type AntModalWrapperProps = {
  onClose: () => void
  Modal: FC
  closeOnClickOutside?: boolean
  props?
}

const AntModalWrapper: FC<AntModalWrapperProps> = ({ onClose, Modal, closeOnClickOutside = true, ...props }) => {
  const handleClose = event => {
    const classAttr = event?.target.getAttribute('class')
    if (!closeOnClickOutside && classAttr && classAttr.includes('ant-modal-wrap')) {
      return
    }
    onClose()
  }

  // @ts-ignore
  return <Modal {...{ onClose: handleClose, ...props }} />
}

export default AntModalWrapper
