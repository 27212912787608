import React, { FC } from 'react'
import { Link as ExternalLink } from '@mui/material'
import { default as InternalLink } from 'next/link'

interface Props {
  href: string
  external?: boolean
  children: any
  ariaLabel?: string
  target?: string
  rel?: string
}

const Link: FC<Props> = ({ href, external, children, ariaLabel, target, rel }) => {
  return (
    <>
      {external ? (
        <ExternalLink href={href} aria-label={ariaLabel ?? null} target={target} rel={rel}>
          {children}
        </ExternalLink>
      ) : (
        <InternalLink href={href} passHref={true} aria-label={ariaLabel}>
          {children}
        </InternalLink>
      )}
    </>
  )
}

export default Link
