import { FC } from 'react'
import { Divider } from '@mui/material'
import { Reinsurance } from '@components/reinsurance'
import BottomFooter from '@components/common/Footer/sections/BottomFooter'
import MainFooter from '@components/common/Footer/sections/MainFooter'

interface ComponentProps {}

const Footer: FC<ComponentProps> = () => {
  return (
    <footer>
      <div className="mt-12.5 md:mt-24.5">
        <Divider />
        <div className="container mx-auto px-4">
          <div className="xl:grid xl:grid-cols-12">
            <div className="xl:col-span-11 xl:col-start-2">
              <Reinsurance classes="py-5 md:py-7.5" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-super-ya-blue1 py-12">
        <div className="container mx-auto">
          <div className="xl:grid xl:grid-cols-12">
            <div className="xl:col-span-11 xl:col-start-2">
              <MainFooter />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-super-ya-blue5 py-12">
        <div className="container mx-auto">
          <BottomFooter />
        </div>
      </div>
    </footer>
  )
}

export default Footer
