import React, { FC, ReactNode } from 'react'
import { Box } from '@mui/material'
import { withStyles } from 'tss-react/mui'

type BannerProps = {
  message: string | ReactNode
  actions?: ReactNode
  backgroundColor?: string
  borderColor?: string
  classes?: string
}

const Banner: FC<BannerProps> = ({ message, actions, backgroundColor, borderColor, classes = '' }) => {
  const Container = withStyles(Box, {
    root: { borderColor, backgroundColor }
  })

  return (
    <Container className={`sm:px-[30px]]mx-auto flex rounded-lg border-[1px] px-[10px] sm:flex-row ${classes} flex-col items-stretch gap-[25px]`}>
      <>{message}</>
      {actions ? <>{actions}</> : null}
    </Container>
  )
}

export default Banner
