import React, { ComponentType, FC } from 'react'
import { currency } from '@components/ui'
import { Typography } from '@mui/material'

const Noop = ({ children }) => {
  return (
    <Typography component="span" className="whitespace-nowrap">
      {children}
    </Typography>
  )
}

interface Props {
  price?: number | string
  Wrapper?: ComponentType
}

const Price: FC<Props> = ({ price, Wrapper = Noop }) => {
  const priceString = typeof price === 'number' ? Math.round(price).toString() : price
  const formattedPrice = priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  return (
    <Wrapper>
      {formattedPrice}
      &nbsp;
      {currency}
    </Wrapper>
  )
}

export default Price
