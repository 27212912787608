interface Route {
  authentication?: boolean
  notLogged?: boolean
  uri: string
  title?: string
  description?: string
}

export interface Routes {
  [key: string]: Route
}

const routes: Routes = {
  error404: {
    uri: '/404',
    title: 'Page non trouvée'
  },
  error500: {
    uri: '/500',
    title: 'Erreur'
  },
  home: {
    uri: '/',
    title: 'Service de gamelle à Nouméa, Nouvelle-Calédonie',
    description: 'Super Ya, votre service de gamelles livrées au bureau ou à domicile sur Nouméa. La qualité au service des Calédoniens depuis 1962.'
  },
  cgv: {
    uri: '/cgv',
    title: 'Conditions générales de vente'
  },
  comment_ca_marche: {
    uri: '/comment-ca-marche',
    title: 'Comment ça marche'
  },
  confirmation: {
    uri: '/confirmation',
    title: 'Commande confirmée'
  },
  connexion: {
    notLogged: true,
    uri: '/connexion',
    title: 'Connexion'
  },
  contact: {
    uri: '/contact',
    title: 'Nous contacter'
  },
  creer_un_compte: {
    uri: '/creer-un-compte',
    title: 'Créer un compte'
  },
  donnees_personnelles: {
    uri: '/donnees-personnelles',
    title: 'Données personnelles'
  },
  livraison: {
    uri: '/livraison',
    title: 'Livraison'
  },
  mentions_legales: {
    uri: '/mentions-legales',
    title: 'Mentions légales'
  },
  mon_compte: {
    uri: '/mon-compte',
    title: 'Mon compte'
  },
  mon_compte_mes_adresses: {
    uri: '/mon-compte/mes-adresses',
    title: 'Mes adresses'
  },
  mon_compte_mes_avoirs: {
    uri: '/mon-compte/mes-avoirs',
    title: 'Mes avoirs'
  },
  mon_compte_mes_commandes: {
    uri: '/mon-compte/mes-commandes',
    title: 'Mes commandes'
  },
  mon_compte_mes_infos_de_connexion: {
    uri: '/mon-compte/mes-infos-de-connexion',
    title: 'Mes infos de connexion  '
  },
  mon_compte_mon_calendrier: {
    uri: '/mon-compte/mon-calendrier',
    title: 'Mon calendrier'
  },
  mon_compte_mon_profil: {
    uri: '/mon-compte/mon-profil',
    title: 'Mon profil'
  },
  mon_panier: {
    uri: '/mon-panier',
    title: 'Mon panier'
  },
  menus: {
    uri: '/menus',
    title: 'Menus'
  },
  mot_de_passe_oublie: {
    uri: '/mot-de-passe-oublie',
    title: 'Mot de passe oublie'
  },
  reset_password: {
    uri: '/registration/reset-password',
    title: 'Réinitialiser mot de passe'
  }
}

export default routes
