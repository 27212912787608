import { StoreWrapper } from '@utils/context/storeContext'
import React, { FC, PropsWithChildren } from 'react'

const withLayout = (Page: FC, Layout: FC<PropsWithChildren<any>>): FC => {
  return function WithLayout() {
    return (
      <Layout>
        <Page />
      </Layout>
    )
  }
}

export const withStore = (Page: FC) => withLayout(Page, StoreWrapper)

export default withLayout
