import { adaptV4Theme, createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import colors from '@const/colors.json'
import { inputLabelClasses } from '@mui/material'
const { black1, blue3, white, black2 } = colors
import type {} from '@mui/x-date-pickers/themeAugmentation'

// Create a theme instance.
const theme = createTheme(
  adaptV4Theme({
    spacing: 5,
    typography: {
      fontFamily: 'Nunito',
      h1: {
        fontFamily: 'Newsreader',
        fontSize: '55px',
        fontWeight: 'bold'
      },
      h2: {
        fontFamily: 'Newsreader',
        fontSize: '2.1875rem',
        color: black1
      },
      h3: {
        fontFamily: 'Newsreader',
        fontSize: '1.55rem'
      },
      subtitle2: {
        fontSize: '1.125rem',
        fontWeight: 600
      },
      body1: {
        fontWeight: 600
      },
      body2: {
        fontFamily: 'Inter',
        fontWeight: 500
      }
    },
    palette: {
      primary: {
        main: blue3
      },
      secondary: {
        main: white
      },
      error: {
        main: red.A400
      },
      background: {
        default: white
      },
      text: {
        primary: black2,
        secondary: white
      }
    },
    overrides: {
      MuiFormHelperText: {
        root: {
          color: black2
        }
      },
      MuiInputBase: {
        root: {
          backgroundColor: 'white',
          height: 50
        },
        multiline: {
          height: 'inherit'
        }
      },
      MuiInputLabel: {
        root: {
          backgroundColor: 'none',
          color: black2,
          fontWeight: 'bold',
          [`&.${inputLabelClasses.focused}`]: {
            color: black2,
            fontWeight: 'bold'
          }
        },
        outlined: {
          transform: 'none',
          [`&.${inputLabelClasses.shrink}`]: {
            transform: 'none'
          }
        },
        formControl: {
          position: 'relative',
          marginBottom: 20
        }
      },
      MuiOutlinedInput: {
        input: {
          paddingBottom: 10,
          paddingTop: 10
        },
        notchedOutline: {
          '& legend': {
            maxWidth: 0,
            transition: 'none'
          }
        }
      }
    }
  }),
  {
    components: {
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            fontWeight: 900
          }
        }
      },
      MuiDayPicker: {
        styleOverrides: {
          weekDayLabel: {
            color: colors.black3,
            fontWeight: 900
          },
          slideTransition: {
            minHeight: 320
          }
        }
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: colors.blue3
          }
        }
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            overflow: 'hidden'
          }
        }
      }
    }
  }
)

export default theme
