import { FC } from 'react'
import Link from 'next/link'
import { Typography } from '@mui/material'
import menus from '@const/menu.json'
import { SubMenuItem } from 'types/menu'

interface ComponentProps {}

const BottomFooter: FC<ComponentProps> = () => {
  const { subfooter } = menus
  // @ts-ignore
  const subMenuItems: Array<SubMenuItem> = subfooter.map((key: string): any => menus[key])

  return (
    <div className="grid grid-cols-12">
      <div className="md:col-start-0 col-span-10 col-start-2 lg:col-span-12">
        <div className="mt-7.5 grid grid-cols-2 sm:grid-cols-3 sm:place-items-center lg:grid-cols-6">
          {subMenuItems.map((item, index) => (
            <div className="mb-7.5" key={index}>
              <Link href={item.url} as={item.as} passHref={true}>
                <Typography component="span" variant="body2" color="textSecondary">
                  {item.label}
                </Typography>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default BottomFooter
