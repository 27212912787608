import { EstimateLine } from '@common/models/estimateLine/estimateLine'
import { coreStore } from '@core/store/coreStore'
import { SkIconUilShoppingCart } from '@core/global/icons'
import { Planning } from '@common/models/planning/planning'
import { Package } from '@common/models/package/package'
import moment from 'moment'
import { runInAction, toJS } from 'mobx'
import { AbstractEstimate } from '@common/models/estimate/abstractEstimate'
import { Variant } from '@common/models/variant/variant'
import { ApiStore } from '@core/api/apiStore'
import { EstimateStateEnum } from '@common/global/enums'
import { Http } from '@core/api/http'
import { EstimateLineTax } from '@common/models/estimateLine/estimateLineTax'
import { EstimateLinePeriod } from '@common/models/estimateLine/estimateLinePeriod'

export class EstimateLineStore extends ApiStore<EstimateLine> {
  constructor() {
    super(coreStore.paramAppStore.apiUrl, '/estimate-line', EstimateLine, 'female', 'Lignes commande', 'une ligne commande', 'cette ligne commande', SkIconUilShoppingCart)
    this.withMessageSave = false
    this.canBePathPublicIfNotMe = true
  }

  $simulate = async (planning: Planning, pack: Package, quantity: number) => {
    return this.$post(new EstimateLine({ quantity, planning, package: pack }), { subUrlPath: '/simulate' })
  }

  $getForEstimate(estimateId: number, startDate: moment.Moment, endDate: moment.Moment) {
    return this.$all({
      params: { filterInit: { estimate: { id: estimateId } }, customFilterInit: { planningDate: { from: startDate.format(), to: endDate.format() } } }
    }).then(data => {
      return this.constructWithChildren(data)
    })
  }

  $allForEstimate(estimateId: number) {
    return this.$all({
      params: { filterInit: { estimate: { id: estimateId } } }
    }).then(data => {
      return this.constructWithChildren(data)
    })
  }

  $existForEstimate(estimateId: number) {
    return this.$first({
      params: { filterInit: { estimate: { id: estimateId } } }
    }).then(data => {
      return data != null
    })
  }

  $allForInvoiceDeadline(invoiceDeadlineId: number) {
    return this.$all({
      params: { filterInit: { invoiceDeadline: { id: invoiceDeadlineId } } }
    }).then(data => {
      return this.constructWithChildren(data)
    })
  }

  $getForSubscription(estimateId: number) {
    return this.$all({
      params: { filterInit: { estimate: { id: estimateId } } }
    }).then(data => {
      return this.constructWithChildren(data)
    })
  }

  $allEstimateLinesWithoutPlanning(estimateId: number) {
    return this.$all({ params: { filterInit: { estimate: { id: estimateId } }, customFilterInit: { withPlanning: false } } }).then(data => this.returnAllWithoutCanceled(data))
  }

  $addLinePackage = async (estimate: AbstractEstimate, planning: Planning, pack: Package, quantity: number) => {
    await this.$post(new EstimateLine({ estimate, planning, package: pack, quantity }))
  }

  $ordering = async (estimateLines: EstimateLine[]) => {
    if (!estimateLines?.length) return Promise.resolve()
    return await Http.$put(
      `${this.baseUrl}/ordering`,
      estimateLines.map(x => ({ id: x.id, idx: x.idx, sectionId: x.sectionId, label: x.label }))
    )
  }

  $addLineVariant = async (estimate: AbstractEstimate, variant: Variant, quantity: number) => {
    await this.$post(new EstimateLine({ estimate, variant, quantity }))
  }

  $deletePackageByPlanning = async (estimate: AbstractEstimate, planning: Planning, pack?: Package) => {
    await this.$delete({
      subUrlPath: '/estimate-planning-package',
      params: { estimateId: estimate.id, planningId: planning.id, ...(pack ? { packageId: pack?.id } : {}) }
    })
  }

  $deletePackageByDay = async (estimate: AbstractEstimate, day: number, pack?: Package) => {
    await this.$delete({
      subUrlPath: '/estimate-planning-package',
      params: { estimateId: estimate.id, day: day, ...(pack ? { packageId: pack?.id } : {}) }
    })
  }

  $addLinePackageForSubscription = async (subscription: AbstractEstimate, day: number, pack: Package, quantity: number) => {
    await this.$post(new EstimateLine({ estimate: subscription, day, package: pack, quantity }))
  }

  $getChildren = async (parent: EstimateLine) => {
    return this.$all({ params: { filterInit: { parent: { id: parent.id }, estimate: { id: parent.estimate.id } } } }).then(data => this.returnAllWithoutCanceled(data))
  }

  $getAllWithDegressiveGroupId = async (degressiveGroupId: number) => {
    return this.$all({ params: { filterInit: { degressiveGroup: { id: degressiveGroupId } } } }).then(data => this.returnAllWithoutCanceled(data))
  }

  $setQuantity = async (line: EstimateLine, quantity: number) => {
    line.ui.totalPriceIsChanging = true
    if (quantity === 0) {
      await this.$deleteById(line.id)
      runInAction(() => (line.id = null))
    } else {
      // save context
      const children = line.children
      line.quantity = quantity
      await this.$save(line)
      // set context and reload children to have new price
      await runInAction(async () => {
        line.children = children
        // reload children if with id & isQuantityFromParent (no fake)
        if (line.children?.some(x => x.id && x.isQuantityFromParent)) {
          const children = await this.$getChildren(line)
          if (children?.length) {
            runInAction(() => (line.children = children))
          }
        }
      })
    }
  }

  $duplicate(item: EstimateLine) {
    const data = new EstimateLine(toJS(item))
    data.id = null
    data.taxes = data.taxes.map(tax => new EstimateLineTax({ taxCode: tax.taxCode, taxRate: tax.taxRate, taxLabel: tax.taxLabel }))
    return this.$save(data)
  }

  $updatePeriod(item: EstimateLinePeriod) {
    return Http.$put(`${this.baseUrl}/${item.id}/period`, item)
  }

  private returnAllWithoutCanceled(data: EstimateLine[]) {
    if (!data) return null
    return data.filter(x => !x.state || (x.state.id !== EstimateStateEnum.canceled && x.state.id !== EstimateStateEnum.deleted))
  }

  private constructWithChildren(data: EstimateLine[]) {
    const values: EstimateLine[] = []
    data.filter(x => !x.parent && (!x.state || (x.state.id !== EstimateStateEnum.canceled && x.state.id !== EstimateStateEnum.deleted))).forEach(x => values.push(x))
    values.forEach((value, index) => {
      const children = data.filter(x => x.parent?.id === value.id && (!x.state || (x.state.id !== EstimateStateEnum.canceled && x.state.id !== EstimateStateEnum.deleted)))
      if (children.length) values[index].children = [...children]
    })
    return values
  }
}
